import {ActionCreatorDefinition, createAction} from "redux-ts";
import {AnswerInfo} from "kbd/protocol/AnswerInfo";
import {ProfessionalClientAccountConfigurations} from "kbd/protocol/response/ProfessionalClientAccountConfigurations";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";

export const SetProfessionalConfigurationsType: string =  "SetProfessionalConfigurations";
export const SetProfessionalQuestionsType: string =  "SetProfessionalQuestions";
export const ProceedToProfessionalQuestionsType: string = "ProceedToProfessionalQuestions";
export const DoSubmitQuestionnaireType: string = "DoSubmitQuestionnaire";
export const SetProfessionalDocumentsOrderType: string = "SetProfessionalDocumentsOrder";
export const SetProfessionalActiveDocumentType: string = "SetProfessionalActiveDocument";

export interface SetProfessionalConfigurationsPayload {

    configurations: ProfessionalClientAccountConfigurations;
}

export interface SetProfessionalQuestionsPayload {

    answers: {[key: string]: AnswerInfo[]};
}

export interface DoSubmitProfessionalQuestionnairePayload {

    values: ProfessionalClientAccountConfigurations;
}

export interface SetProfessionalActiveDocumentPayload {

    docType: ProofDocumentType;
}

export const SetProfessionalConfigurations: ActionCreatorDefinition<SetProfessionalConfigurationsPayload, any> = createAction<SetProfessionalConfigurationsPayload>(SetProfessionalConfigurationsType);
export const SetProfessionalQuestions: ActionCreatorDefinition<SetProfessionalQuestionsPayload, any> = createAction<SetProfessionalQuestionsPayload>(SetProfessionalQuestionsType);
export const ProceedToProfessionalQuestions: ActionCreatorDefinition<any, any> = createAction<any>(ProceedToProfessionalQuestionsType);
export const DoSubmitQuestionnaire: ActionCreatorDefinition<DoSubmitProfessionalQuestionnairePayload, any> = createAction<DoSubmitProfessionalQuestionnairePayload>(DoSubmitQuestionnaireType);
export const SetProfessionalDocumentsOrder: ActionCreatorDefinition<any, any> = createAction<any>(SetProfessionalDocumentsOrderType);
export const SetProfessionalActiveDocument: ActionCreatorDefinition<SetProfessionalActiveDocumentPayload, any> = createAction<SetProfessionalActiveDocumentPayload>(SetProfessionalActiveDocumentType);
