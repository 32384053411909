import {Engine} from "platform/engine/Engine";
import Utils from "platform/util/Utils";
import {LangCode} from "platform/enum/LangCode";
import {HttpReject} from "platform/network/http/Http";
import {GetProofDocumentTypesRequest} from "kbd/protocol/request/GetProofDocumentTypesRequest";
import {GetProofDocumentTypesResponse} from "kbd/protocol/response/GetProofDocumentTypesResponse";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {TSMap} from "typescript-map";
import {
    DoCancelDocumentPayload,
    RemoveDocumentContent,
    SetKycCreditCards,
    SetDocumentStatuses,
    SetDocumentSubType,
    SetDocumentTypes,
    SetSelectedCreditCard,
    SetUploadCancelDocInProgress,
    SetUploadDocumentErrors,
    SetUsedCreditCardUploaded,
    SetUsedCreditCards,
    SetWizardDocsIndex,
    UploadDocumentBatchPayload
} from "kbd/core/redux/documents/DocumentsReduxActions";
import Platform from "platform/Platform";
import {GetDocumentsResponse} from "kbd/protocol/response/GetDocumentsResponse";
import {ProofIdDocumentType} from "kbd/protocol/ProofIdDocumentType";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import {ResidenceProofDocumentType} from "kbd/protocol/ResidenceProofDocumentType";
import {StoreState} from "kbd/core/redux/StoreState";
import {CancelDocumentRequest} from "kbd/protocol/request/CancelDocumentRequest";
import {OperationResult} from "kbd/protocol/OperationResult";
import {UploadDocumentRequest} from "kbd/protocol/request/UploadDocumentRequest";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {FileToBase64, isDocumentRejected, isSkipDocumentsPage} from "kbd/core/util/KycUtil";
import {FileType} from "kbd/enum/FileType";
import {UploadDocumentResponse} from "kbd/protocol/response/UploadDocumentResponse";
import {UploadFileInfo} from "kbd/protocol/UploadFileInfo";
import {DocumentPayload, DocumentPayloadType} from "kbd/core/redux/documents/DocumentsReduxState";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {Route, RouterState} from "platform/redux/PlatformReduxState";
import {PageType} from "kbd/enum/PageType";
import {IPage} from "kbd/pages/IPage";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {DoRegisterActivity, SetKycStepStatus} from "kbd/core/redux/kyc/KycReduxActions";
import {KycStepType} from "kbd/enum/KycStepType";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import {HideLoader, NavigateTo, SetLoader} from "platform/redux/core/CoreActions";
import {BadRequestResponse} from "kbd/protocol/response/BadRequestResponse";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {GetDocumentsRequest} from "kbd/protocol/request/GetDocumentsRequest";
import {DocumentsTarget} from "kbd/enum/DocumentsTarget";
import {LoadLanguagePayload} from "platform/redux/translation/TranslationActions";
import {DocumentUploadSection} from "kbd/enum/DocumentUploadSection";
import {ProductType} from "kbd/entry/ProductType";
import {GetUsedCreditCardsRequest} from "kbd/protocol/request/GetUsedCreditCardsRequest";
import {DocumentCreditCard, GetUsedCreditCardsResponse} from "kbd/protocol/response/GetUsedCreditCardsResponse";
import {LogDocumentSeenDateRequest} from "kbd/protocol/request/LogDocumentSeenDateRequest";
import {LoaderType} from "platform/enum/LoaderType";
import {SetProfessionalActiveDocument} from "kbd/core/redux/professional/ProfessionalReduxActions";
import {docSubTypeLocalizedName, DocTypeLocalizedName, documentsSortCreditCards} from "kbd/core/util/DocumentsUtil";
import {NextPage} from "kbd/core/redux/app/AppReduxActions";
import {BIEventType} from "kbd/enum/BIEventType";
import { ShowPopup } from "platform/redux/popups/PopupsActions";
import { PopupActionType, PopupIconType, PopupType } from "platform/redux/popups/PopupsReduxState";

export default class DocumentsEngine extends Engine {

    private static _instance: DocumentsEngine;
    private _documentConfigRequested: boolean;

    public static instance(): DocumentsEngine {
        return this._instance || (this._instance = new this());
    }

    public onChangeRoute = (payload): void => {
        const route: Route = payload.route;
        const pageType: PageType = route.name as PageType;
        const langCode: LangCode = LanguageUtil.languageCode();
        if (PageNavigator.isDocumentPage(pageType)) {
            const callback = () => {
                if (isSkipDocumentsPage(pageType)) {
                    this._logger.debug("Skip not needed Proof Doc page: " + pageType + " without already uploaded docs");
                    const prevRoute: Route = payload.previousRoute;
                    const isForward: boolean = PageNavigator.isForward(prevRoute ? prevRoute.name as PageType : null, pageType);
                    const page: IPage = isForward ? PageNavigator.next(pageType) : PageNavigator.prev(pageType);
                    if (page) {
                        setTimeout(() => {
                            Platform.dispatch(NavigateTo({route: page.type}));
                            if (isForward && !PageNavigator.isDocumentPage(page.type)) {
                                Platform.dispatch(SetKycStepStatus({
                                    stepType: KycStepType.IdentityVerification,
                                    status: KycStepStatus.Completed
                                }));
                                Platform.dispatch(SetKycStepStatus({
                                    stepType: page.kycStep,
                                    status: KycStepStatus.InProgress
                                }));
                            }
                        }, 0);
                    }
                } else {
                    const activityTypeInfo: ActivityTypeInfo = ActivityTypeInfo.pageOpenActivityType(pageType);
                    if (Utils.isNotNull(activityTypeInfo)) {
                        Platform.dispatch(DoRegisterActivity({activityType: activityTypeInfo}));
                    }
                }
            };
            if (!this._documentConfigRequested) {
                this._documentConfigRequested = true;
                this.fetchProofDocumentTypes(langCode, DocumentsTarget.Retail)
                    .then((documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>) => {
                        this.fetchDocuments(langCode, DocumentUploadSection.FullDocumentUpload, documentTypes).then(callback).catch(() => {
                        });
                    })
                    .catch(() => {
                    });
            } else {
                callback();
            }
        } else {
            this._documentConfigRequested = false;
            if (pageType === PageType.KycCongratulation) {
                Platform.dispatch(SetKycStepStatus({
                    stepType: KycStepType.IdentityVerification,
                    status: KycStepStatus.Completed
                }));
                Platform.dispatch(SetKycStepStatus({
                    stepType: KycStepType.StartTrading,
                    status: KycStepStatus.InProgress
                }));
            } else if (pageType === PageType.ProfessionalDocuments) {
                this.fetchProofDocumentTypes(langCode, DocumentsTarget.Professional)
                    .then((documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>) => {
                        this.fetchDocuments(langCode, DocumentUploadSection.FullDocumentUpload, documentTypes).catch(() => {
                        });
                    })
                    .catch(() => {
                    });
            }
        }
    }

    public onLoadLanguage = async (payload: LoadLanguagePayload) => {
        const {langCode} = Platform.reduxState().translation;
        const router: RouterState = Platform.reduxState().router;
        if (router.route && router.route.name && router.route.name === PageType.DocumentsOverview && Utils.isEmpty(langCode) && payload.langCode) {
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo> = await this.fetchProofDocumentTypes(payload.langCode, DocumentsTarget.All);
            const documents: DocumentInfo[] = await this.fetchDocuments(payload.langCode, DocumentUploadSection.FullDocumentUpload, documentTypes);
            const creditCards: DocumentCreditCard[] = await this.fetchUsedCreditCards();
            if (Utils.isArrayNotEmpty(documents)) {
                const documentIds: number[] = [];
                documents.forEach((document: DocumentInfo) => {
                    documentIds.push(document.Id);
                    if (document.LastFourDigitsOfCreditCard && !isDocumentRejected(document)) {
                        const creditCard: DocumentCreditCard = creditCards.filter((cc: DocumentCreditCard) => cc.Last4digits === document.LastFourDigitsOfCreditCard)[0];
                        if (creditCard) {
                            Platform.dispatch(SetUsedCreditCardUploaded({
                                last4Digits: creditCard.Last4digits,
                                uploaded: true
                            }));
                        }
                    }
                });
                this._logger.debug("Report docs seen date for: " + documentIds);
                const request: LogDocumentSeenDateRequest = {DocumentIds: documentIds};
                await Utils.to(XhrManager.sendToDocuments(request, "LogDocumentSeenDate"));
            }
            const cards: DocumentCreditCard[] = documentsSortCreditCards(documents, creditCards);
            if (Utils.isArrayNotEmpty(cards)) {
                Platform.dispatch(SetSelectedCreditCard({creditCard: cards[0]}));
            }
            Platform.dispatch(SetUsedCreditCards({cards}));
            Platform.dispatch(HideLoader({}));
        }
    }

    private fetchProofDocumentTypes = async (langCode: LangCode, target: DocumentsTarget): Promise<TSMap<ProofDocumentType, ProofDocumentTypeInfo>> => {
        const request: GetProofDocumentTypesRequest = {
            LanguageCode: langCode,
            FilterDocumentType: target
        };
        const answer: [HttpReject, GetProofDocumentTypesResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetProofDocumentTypes"));
        if (answer[0]) {
            this._logger.debug("Failed fetch proof doc types. Status: " + answer[0].status);
        } else {
            const response: GetProofDocumentTypesResponse = answer[1];
            if (Utils.isArrayNotEmpty(response.ProofDocumentTypes)) {
                const documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo> = new TSMap();
                response.ProofDocumentTypes.forEach((documentTypeInfo: ProofDocumentTypeInfo) => {
                    if (documentTypeInfo.Id === ProofDocumentType.ProofOfBankDetails && Utils.isArrayEmpty(documentTypeInfo.SubDocumentTypes)) {
                        // NOTE: fake sub document type only for client usage
                        documentTypeInfo.SubDocumentTypes = [
                            {Id: -1, IsBothSidesRequired: false, LocalizedName: null}
                        ];
                    }
                    if (documentTypeInfo.Id === ProofDocumentType.ProofOfId) {
                        Platform.dispatch(SetDocumentSubType({
                            documentType: ProofDocumentType.ProofOfId,
                            subType: (documentTypeInfo.SubDocumentTypes[0] || {}).Id || ProofIdDocumentType.NationalIdCard
                        }));
                    } else if (documentTypeInfo.Id === ProofDocumentType.ProofOfResidence) {
                        Platform.dispatch(SetDocumentSubType({
                            documentType: ProofDocumentType.ProofOfResidence,
                            subType: (documentTypeInfo.SubDocumentTypes[0] || {}).Id || ResidenceProofDocumentType.UtilityBill
                        }));
                    } else if (documentTypeInfo.Id !== ProofDocumentType.ProofOfCC) {
                        Platform.dispatch(SetDocumentSubType({
                            documentType: documentTypeInfo.Id,
                            subType: (documentTypeInfo.SubDocumentTypes[0] || {}).Id
                        }));
                    }
                    documentTypes.set(documentTypeInfo.Id, documentTypeInfo);
                });
                Platform.dispatch(SetDocumentTypes({
                    documentTypes
                }));
                return Promise.resolve(documentTypes);
            }
        }
        return Promise.resolve(new TSMap<ProofDocumentType, ProofDocumentTypeInfo>());
    }

    private fetchDocuments = async (langCode: LangCode, section: DocumentUploadSection, documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>): Promise<DocumentInfo[]> => {
        this._logger.debug("Fetching documents...");
        const request: GetDocumentsRequest = {LanguageCode: langCode, UploadSection: section};
        const answer: [HttpReject, GetDocumentsResponse] = await Utils.to(XhrManager.sendToDocuments(request, "GetDocuments"));
        if (answer[0]) {
            this._logger.debug("Failed fetch docs. Status: " + answer[0].status);
        } else {
            const response: GetDocumentsResponse = answer[1];
            if (Utils.isArrayNotEmpty(response.CreditCardDetails)) {
                Platform.dispatch(SetKycCreditCards({creditCards: response.CreditCardDetails}));
            }
            if (Utils.isArrayNotEmpty(response.Documents)) {
                const documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>> = new TSMap();
                response.Documents.forEach((document: DocumentInfo) => {
                    if (documentTypes.has(document.DocumentTypeId)) {
                        if (document.DocumentTypeId === ProofDocumentType.ProofOfBankDetails && Utils.isNull(document.SubDocumentTypeId)) {
                            document.SubDocumentTypeId = -1;
                        }
                        const subDocType: number | string = document.DocumentTypeId === ProofDocumentType.ProofOfCC ? document.LastFourDigitsOfCreditCard
                            : document.DocumentTypeId === ProofDocumentType.Miscellaneous ? document.Id : document.SubDocumentTypeId;
                        const documents: TSMap<number | string, DocumentInfo> = documentStatuses.get(document.DocumentTypeId) || new TSMap();
                        documentStatuses.set(document.DocumentTypeId, documents);
                        documents.set(subDocType, document);
                        const isDocRejected: boolean = isDocumentRejected(document);
                        if (document.DocumentTypeId !== ProofDocumentType.ProofOfCC && document.DocumentTypeId !== ProofDocumentType.Miscellaneous
                                && (documents.size() === 1 || !isDocRejected)) {
                            Platform.dispatch(SetDocumentSubType({
                                documentType: document.DocumentTypeId,
                                subType: document.SubDocumentTypeId
                            }));
                        }
                    }
                });
                documentStatuses.values().forEach((documents: TSMap<number | string, DocumentInfo>) => {
                    documents.forEach((Document: DocumentInfo, subDocType: number | string) => {
                        if (isDocumentRejected(Document)) {
                            Platform.dispatch(RemoveDocumentContent({
                                docType: Document.DocumentTypeId, subDocType
                            }));
                        }
                    });
                });
                Platform.dispatch(SetDocumentStatuses({documentStatuses}));
                return Promise.resolve(response.Documents);
            }
        }
        return Promise.resolve([]);
    }

    private fetchUsedCreditCards = async (): Promise<DocumentCreditCard[]> => {
        const request: GetUsedCreditCardsRequest = {};
        const answer: [HttpReject, GetUsedCreditCardsResponse] = await Utils.to(XhrManager.sendToDocuments(request, "GetAllUsedCreditCards"));
        if (answer[0]) {
            this._logger.debug("Failed fetch used credit cards. Status: " + answer[0].status);
        } else {
            const response: GetUsedCreditCardsResponse = answer[1];
            if (response.UsedCreditCards) {
                return Promise.resolve(response.UsedCreditCards);
            }
        }
        return Promise.resolve([]);
    }

    private addDocument = (Files: UploadFileInfo[], isFrontSide: boolean, additional: boolean, fileName: string, fileType: FileType, base64: string): void => {
        const mimeType: string = `data:${fileType};base64,`;
        Files.push({
            FileNameWithExtension: fileName,
            ContentType: fileType,
            File: base64.replace(mimeType, ""),
            IsFrontSide: isFrontSide,
            IsAdditionalFile: additional
        });
    };

    private UploadDocuments = async (docType: ProofDocumentType, subDocType: number | string, Files: UploadFileInfo[]): Promise<boolean> => {
        if (Utils.isArrayNotEmpty(Files)) {
            const {documentTypes, kycCreditCards, creditCards} = Platform.reduxState<StoreState>().documents;
            const docInfo: ProofDocumentTypeInfo = documentTypes.get(docType);
            const isCC: boolean = docInfo.Id === ProofDocumentType.ProofOfCC;
            this._logger.debug("Going to upload docs for doc type: " + docType + " sub doc type: " + subDocType);
            const productType: ProductType = Platform.store<StoreState>().getState().kbdApp.product;
            const langCode: LangCode = LanguageUtil.languageCode();
            let Bin: string;
            if (isCC) {
                const searchInCards: DocumentCreditCard[] = productType === ProductType.Kyc ? kycCreditCards : creditCards;
                if (Utils.isArrayNotEmpty(searchInCards)) {
                    for (let i = 0; i < searchInCards.length; i++) {
                        const cc: DocumentCreditCard = searchInCards[i];
                        if (cc?.Last4digits === subDocType) {
                            Bin = cc.Bin;
                            break;
                        }
                    }
                }
            }
            const request: UploadDocumentRequest = {
                LanguageCode: langCode,
                UploadSection: productType === ProductType.Kyc ? DocumentUploadSection.Kyc : DocumentUploadSection.FullDocumentUpload,
                ProofDocumentTypeId: docType,
                ProofSubDocumentTypeId: isCC || (subDocType as number) < 0 ? null : subDocType as number,
                Bin,
                LastFourDigitsOfCreditCard: isCC ? subDocType as string : null,
                Files
            };
            Platform.dispatch(SetUploadCancelDocInProgress({inProgress: true}));
            const answer: [HttpReject, UploadDocumentResponse] = await Utils.to(XhrManager.sendToDocuments(request, "UploadDocument"));
            Platform.dispatch(SetUploadDocumentErrors({
                docType, subDocType, errors: null
            }));
            Platform.dispatch(SetUploadCancelDocInProgress({inProgress: false}));
            if (answer[0]) {
                this._logger.info("Failed upload document. Status: " + answer[0].status);
                Platform.bi().track(BIEventType.DocumentError, {
                    DocumentType: DocTypeLocalizedName(docType) || docType,
                    DocumentSubType: docSubTypeLocalizedName(docType, subDocType) || subDocType,
                    ErrorDescription: `Response status: ${answer[0].status}`
                });
                XhrManager.notifyRejectReason(answer);
            } else {
                const {Documents, Success, Errors} = answer[1];
                if (Success) {
                    Platform.bi().track(BIEventType.DocumentUploadSuccess, {
                        DocumentType: DocTypeLocalizedName(docType) || docType,
                        DocumentSubType: docSubTypeLocalizedName(docType, subDocType) || subDocType,
                    });
                    if (Utils.isArrayNotEmpty(Documents)) {
                        const documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>> = Platform.reduxState<StoreState>().documents.documentStatuses;
                        const newStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>> = documentStatuses.clone();
                        Documents.forEach((document: DocumentInfo) => {
                            if (document.DocumentTypeId === ProofDocumentType.ProofOfBankDetails && Utils.isNull(document.SubDocumentTypeId)) {
                                document.SubDocumentTypeId = -1;
                            }
                            const documents: TSMap<number | string, DocumentInfo> = newStatuses.get(docType) || new TSMap();
                            const newDocuments: TSMap<number | string, DocumentInfo> = documents.clone();
                            newStatuses.set(docType, newDocuments);
                            newDocuments.set(docType === ProofDocumentType.Miscellaneous ? document.Id : subDocType, document);
                            if (document.LastFourDigitsOfCreditCard) {
                                Platform.dispatch(SetUsedCreditCardUploaded({
                                    last4Digits: document.LastFourDigitsOfCreditCard,
                                    uploaded: true
                                }));
                            }
                        });
                        Platform.dispatch(SetDocumentStatuses({documentStatuses: newStatuses}));
                        if (docType === ProofDocumentType.Miscellaneous) {
                            Platform.dispatch(RemoveDocumentContent({
                                docType: ProofDocumentType.Miscellaneous, subDocType
                            }));
                        }
                    } else {
                        this._logger.warn("Receive empty document after upload");
                    }
                    return Promise.resolve(true);
                } else {
                    Platform.bi().track(BIEventType.DocumentError, {
                        DocumentType: DocTypeLocalizedName(docType) || docType,
                        DocumentSubType: docSubTypeLocalizedName(docType, subDocType) || subDocType,
                        ErrorDescription: Errors
                    });
                    Platform.dispatch(SetUploadDocumentErrors({
                        docType, subDocType, errors: Errors
                    }));
                }
            }
        }
        return Promise.resolve(false);
    }

    public doUploadDocumentBatch = async ({docType, subDocType, nextPage, fromPage, wizardIndex, nextDocType}: UploadDocumentBatchPayload) => {
        const {documentContents} = Platform.reduxState<StoreState>().documents;
        const payloadsMap: TSMap<number | string, DocumentPayload[]> = documentContents.get(docType);
        if (payloadsMap) {
            const payloads: DocumentPayload[] = payloadsMap.get(subDocType);
            if (Utils.isArrayNotEmpty(payloads)) {
                this._logger.debug("Try to upload docs batch for docType: " + docType + " subDocType: " + subDocType);
                const Files: UploadFileInfo[] = [];
                for (let i = 0; i < payloads.length; i++) {
                    const payload: DocumentPayload = payloads[i];
                    if (payload.payloadType === DocumentPayloadType.File) {
                           try {
                            const base64: string = await FileToBase64(payload.payload as File);
                            this.addDocument(Files, i === 0, payload.additional, payload.fileName, payload.fileType, base64);
                           } catch (e){
                            this._logger.warn("Can't read file from path. " + payload.fileName);
                            Platform.dispatch(ShowPopup({
                                popup: {
                                    type: PopupType.ERROR,
                                    message: {
                                        trKey: TranslationKey.errorFileReading
                                    },
                                    showClose: true,
                                    icon: {type: PopupIconType.ERROR},
                                    actions: [{type: PopupActionType.OK}]
                                }
                            }));
                            //Please use external browser.
                        }

                    } else {
                        this.addDocument(Files, i === 0, payload.additional, payload.fileName, payload.fileType, payload.payload as string);
                    }
                }
                const answer: [any, boolean] = await Utils.to(this.UploadDocuments(docType, subDocType, Files));
                if (answer[1]) {
                    let creditCard: DocumentCreditCard;
                    const {router, documents} = Platform.reduxState<StoreState>();
                    if (router.route.name === PageType.DocumentPaymentPage) {
                        const {wizardDocsNavigation, creditCards, documentStatuses} = documents;
                        if (Utils.isArrayNotEmpty(wizardDocsNavigation) && wizardDocsNavigation.length > 1) {
                            const ccDocuments: TSMap<number | string, DocumentInfo> = documentStatuses.get(ProofDocumentType.ProofOfCC);
                            for (let i = 0; i < creditCards.length; i++) {
                                const cc: DocumentCreditCard = creditCards[i];
                                const ccDocument: DocumentInfo = ccDocuments.get(cc.Last4digits);
                                if (Utils.isNull(ccDocument) || isDocumentRejected(ccDocument)) {
                                    creditCard = cc;
                                    break;
                                }
                            }
                        }
                    }
                    if (creditCard) {
                        Platform.dispatch(SetSelectedCreditCard({creditCard}));
                    } else {
                        if (Utils.isNotNull(wizardIndex)) {
                            Platform.dispatch(SetWizardDocsIndex({index: wizardIndex}));
                        }
                        if (nextDocType) {
                            Platform.dispatch(SetProfessionalActiveDocument({
                                docType: nextDocType
                            }));
                        }
                        if (nextPage) {
                            Platform.dispatch(NavigateTo({route: nextPage, opts: {replace: true}}));
                        }
                        if (fromPage) {
                            const activityTypeInfo: ActivityTypeInfo = ActivityTypeInfo.pageCloseActivityType(fromPage);
                            if (Utils.isNotNull(activityTypeInfo)) {
                                Platform.dispatch(DoRegisterActivity({activityType: activityTypeInfo}));
                            }
                            Platform.dispatch(NextPage({route: fromPage}));
                        }
                    }
                }
            } else {
                this._logger.debug("Can't upload batch docs for docType: " + docType + " Payloads array empty");
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorFileUpload
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        } else {
            this._logger.debug("Can't upload batch docs for docType: " + docType + " Payloads map empty");
        }
    }

    public doCancelDocument = async (payload: DoCancelDocumentPayload) => {
        if (payload.Document) {
            const router: RouterState = Platform.reduxState().router;
            const isDocsOverview: boolean = router && router.route && router.route.name === PageType.DocumentsOverview;
            const {DocumentTypeId, SubDocumentTypeId, LastFourDigitsOfCreditCard, Id} = payload.Document;
            const subDocType: number | string = DocumentTypeId === ProofDocumentType.ProofOfCC ? LastFourDigitsOfCreditCard
                : DocumentTypeId === ProofDocumentType.Miscellaneous ? Id : SubDocumentTypeId;
            this._logger.info("Try to cancel document: " + Id + " Sub doc type: " + subDocType);
            Platform.dispatch(SetUploadCancelDocInProgress({inProgress: true}));
            if (isDocsOverview) {
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            }
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: CancelDocumentRequest = {LanguageCode: langCode, DocumentIds: [Id]};
            const answer: [HttpReject, OperationResult] = await Utils.to(XhrManager.sendToDocuments(request, "CancelDocument"));
            Platform.dispatch(SetUploadCancelDocInProgress({inProgress: false}));
            if (isDocsOverview) {
                Platform.dispatch(HideLoader({}));
            }
            if (answer[0]) {
                this._logger.info("Failed cancel document: " + Id + " Status: " + answer[0].status);
                Platform.bi().track(BIEventType.DocumentError, {
                    DocumentType: DocTypeLocalizedName(DocumentTypeId) || DocumentTypeId,
                    DocumentSubType: docSubTypeLocalizedName(DocumentTypeId, subDocType) || subDocType,
                    ErrorDescription: Translations.text(TranslationKey.serverErrorGeneral)
                });
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (!response.IsSecurityException) {
                        Platform.dispatch(SetUploadDocumentErrors({
                            docType: DocumentTypeId, subDocType, errors: [
                                {
                                    Message: response.LocalizeMessage || response.Message || Translations.text(TranslationKey.serverErrorGeneral),
                                    Code: null,
                                    PropertyName: null
                                }
                            ]
                        }));
                    }
                } catch (e) {
                }
            } else {
                const result: OperationResult = answer[1];
                if (result.Success) {
                    this._logger.debug("Documents canceled");
                    Platform.bi().track(BIEventType.DocumentCanceledSuccess, {
                        DocumentType: DocTypeLocalizedName(DocumentTypeId) || DocumentTypeId,
                        DocumentSubType: docSubTypeLocalizedName(DocumentTypeId, subDocType) || subDocType,
                    });
                    if (LastFourDigitsOfCreditCard) {
                        Platform.dispatch(SetUsedCreditCardUploaded({
                            last4Digits: LastFourDigitsOfCreditCard,
                            uploaded: false
                        }));
                    }
                    const documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>> = Platform.reduxState<StoreState>().documents.documentStatuses;
                    const newStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>> = documentStatuses.clone();
                    const documents: TSMap<number | string, DocumentInfo> = newStatuses.get(DocumentTypeId);
                    if (documents && documents.has(subDocType)) {
                        const newDocuments: TSMap<number | string, DocumentInfo> = documents.clone();
                        newDocuments.set(subDocType, null);
                        newStatuses.set(DocumentTypeId, newDocuments);
                        Platform.dispatch(SetDocumentStatuses({documentStatuses: newStatuses}));
                        Platform.dispatch(RemoveDocumentContent({
                            docType: DocumentTypeId, subDocType
                        }));
                    } else {
                        this._logger.warn("Can't find document by type " + DocumentTypeId + " Sub type: " + subDocType);
                    }
                } else {
                    this._logger.debug("Failed cancel document: " + Id);
                    Platform.bi().track(BIEventType.DocumentError, {
                        DocumentType: DocTypeLocalizedName(DocumentTypeId) || DocumentTypeId,
                        DocumentSubType: docSubTypeLocalizedName(DocumentTypeId, subDocType) || subDocType,
                        ErrorDescription: Translations.text(TranslationKey.serverErrorGeneral)
                    });
                    Platform.dispatch(SetUploadDocumentErrors({
                        docType: DocumentTypeId, subDocType, errors: [
                            {
                                Message: Translations.text(TranslationKey.serverErrorGeneral),
                                Code: null,
                                PropertyName: null
                            }
                        ]
                    }));
                }
            }
        } else {
            this._logger.warn("Can't cancel empty document");
        }
    }
}
