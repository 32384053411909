import {PageNavigator} from "kbd/pages/PageNavigator";
import {PageType} from "kbd/enum/PageType";
import {IPage, IPageField, IPageFieldGroup, IPageForm, IPageFormRow} from "kbd/pages/IPage";
import Utils from "platform/util/Utils";
import {FormikErrors, FormikValues} from "formik";
import {FieldType} from "kbd/enum/FieldType";
import {
    DAYS_IN_MONTH,
    EducationLevelAnswerEnableAreaOfEducation,
    EmploymentStatusAnswerDisableCurrentIndustry
} from "kbd/core/util/KycUtil";
import {Logger} from "platform/logger/Logger";
import {KycState} from "kbd/core/state/KycState";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {ComplianceFormInfo} from "kbd/protocol/ComplianceFormInfo";

export class Validation {

    private static logger: Logger = Logger.Of("Validation");

    public static isFieldVisible(fieldType, values: FormikValues): boolean {
        if (fieldType === FieldType.TradingVolume || fieldType === FieldType.TradingFrequency) {
            const experienceTrading: number = values[FieldType.ExperienceTrading];
            return Utils.isNull(experienceTrading) || experienceTrading !== 5;
        } else if (fieldType === FieldType.IndustryDescription) {
            const industry: number = parseInt(values[FieldType.Industry], 10);
            return industry === 30;
        }
        return true;
    }

    public static async validateForm<Form>(pageType: PageType, values: FormikValues): Promise<FormikErrors<Form>> {
        const errors: FormikErrors<Form> = {};
        const page: IPage = PageNavigator.page(pageType);
        const pageForm: IPageForm = page ? page.form || {} : {};
        if (Utils.isNotNull(pageForm) && Utils.isArrayNotEmpty(pageForm.rows)) {
            for (let i = 0; i < pageForm.rows.length; i++) {
                const row: IPageFormRow = pageForm.rows[i];
                if (row && Utils.isArrayNotEmpty(row.fields)) {
                    for (let j = 0; j < row.fields.length; j++) {
                        const field: IPageField = row.fields[j];
                        if (this.isFieldVisible(field.type, values)) {
                            const value: any = values[field.type];
                            const valid: boolean = await this.defValidateField(field.type, value, values);
                            if (!field.optional && !valid) {
                                errors[field.type] = "Invalid";
                            } else {
                                const pattern: string = field.validation;
                                if (Utils.isNotEmpty(pattern)) {
                                    this.validateRegExp(field.type, pattern, values, errors);
                                }
                            }
                            await this.validateExtra(field, values, errors);
                        }
                    }
                }
            }
        }
        return errors;
    }

    private static async assignErrorIfNeed<Form>(fieldType: FieldType, values: FormikValues, errors: FormikErrors<Form>): Promise<void> {
        const valid: boolean = await this.defValidateField(fieldType, values[fieldType], values);
        if (!valid) {
            errors[fieldType] = "Invalid";
        }
    }

    private static async validateExtra<Form>(field: IPageField, values: FormikValues, errors: FormikErrors<Form>): Promise<void> {
        if (Utils.isNotNull(field.type)) {
            switch (field.type) {
                case FieldType.PhoneNumber:
                    await this.assignErrorIfNeed(FieldType.CountryCallingCode, values, errors);
                    break;
                case FieldType.BirthDate:
                    await this.assignErrorIfNeed(FieldType.BirthDay, values, errors);
                    await this.assignErrorIfNeed(FieldType.BirthMonth, values, errors);
                    await this.assignErrorIfNeed(FieldType.BirthYear, values, errors);
                    const day: number = values[FieldType.BirthDay];
                    const month: number = values[FieldType.BirthMonth];
                    const year: number = values[FieldType.BirthYear];
                    if (Utils.greaterThen0(day) && Utils.greaterThen0(month)) {
                        const daysInMonth: number[] = DAYS_IN_MONTH(month, year);
                        if (day > daysInMonth.length) {
                            this.logger.debug("Reset date field according to days in month: " + daysInMonth.length + " Stored birthday date: " + day);
                            errors[FieldType.BirthDay] = "Invalid";
                        }
                    }
                    break;
                case FieldType.Address:
                    const meta: any = field.meta ? field.meta : {};
                    const group: IPageFieldGroup = meta[field.meta.activeGroup === "group-2" ? "group-2" : "group-1"];
                    const fields: IPageField[] = group ? group.fields : null;
                    if (Utils.isArrayNotEmpty(fields)) {
                        for (let i = 0; i < fields.length; i++) {
                            const f: IPageField = fields[i];
                            await this.assignErrorIfNeed(f.type, values, errors);
                        }
                    }
                    break;
            }
        }
    }

    private static async defValidateField(fieldType: FieldType, value: any, values: FormikValues): Promise<boolean> {
        const kycState: KycState = Platform.state(ServiceType.Kyc);
        if (Utils.isNotNull(fieldType)) {
            switch (fieldType) {
                case FieldType.PhoneNumber:
                    const libphonenumber = await import('libphonenumber-js');
                    const ci: CountryInfo = kycState.getCountryByPhoneCode(values[FieldType.CountryCallingCode]);
                    return ci && value && Utils.isNotEmpty(value.toString()) && libphonenumber.isValidNumber(value.toString(), ci.Code as any);
                case FieldType.CountryCallingCode:
                    return Utils.isNotNull(kycState.getCountryByPhoneCode(value));
                case FieldType.FirstName:
                case FieldType.LastName:
                case FieldType.Email:
                case FieldType.Address:
                case FieldType.City:
                case FieldType.PostalCode:
                case FieldType.PassportNumber:
                    return Utils.isNotEmpty(value);
                case FieldType.BirthDay:
                case FieldType.BirthMonth:
                case FieldType.BirthYear:
                case FieldType.EducationLevel:
                case FieldType.EmploymentStatus:
                case FieldType.PreviousIndustryId:
                case FieldType.PreviousEmploymentDetailsType:
                case FieldType.AnnualIncome:
                case FieldType.SavingsAndInvestments:
                case FieldType.SourceOfWealth:
                case FieldType.ExperienceTrading:
                case FieldType.TradingVolume:
                case FieldType.TradingFrequency:
                case FieldType.ExperienceInFinancialServices:
                case FieldType.MaxLeveragedAmount:
                case FieldType.TradingSharePrice:
                case FieldType.TradingStopLossOrder:
                case FieldType.MarketRateAmount:
                case FieldType.PotentialProfitOrLoose:
                case FieldType.TradingRiskCFDAmount:
                case FieldType.PurposeOfTrading:
                case FieldType.InvestmentHorizonTypeId:
                case FieldType.PlannedTradingAmountPerYear:
                case FieldType.SourceOfTradingFunds:
                case FieldType.MethodOfDeposit:
                case FieldType.ExperienceLength:
                    return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                case FieldType.TaxIdentificationNumber:
                    return Utils.isNotEmpty(value);
                case FieldType.CountryOfResidence:
                case FieldType.CountryOfBirth:
                case FieldType.Nationality:
                case FieldType.CountryOfEmployment:
                case FieldType.CountryOfTaxRegistration:
                    return this.foundCountry(value);
                case FieldType.AreaOfEducationId:
                    const educationLevelAnswer: number = parseInt(values[FieldType.EducationLevel], 10);
                    if (EducationLevelAnswerEnableAreaOfEducation.indexOf(educationLevelAnswer) > -1) {
                        return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                    }
                    return true;
                case FieldType.Industry:
                case FieldType.EmploymentDetailsType:
                    const employmentStatusId: number = parseInt(values[FieldType.EmploymentStatus], 10);
                    if (EmploymentStatusAnswerDisableCurrentIndustry.indexOf(employmentStatusId) >= 0) {
                        return true;
                    }
                    return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                case FieldType.IndustryDescription:
                    const industryAnswer: number = parseInt(values[FieldType.Industry], 10);
                    if (industryAnswer === 30) {
                        return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                    }
                    return true;
                case FieldType.ListedSharesId:
                case FieldType.ExchangeTradedFundsFamiliarityId:
                case FieldType.FinancialDerivativesFamiliarityId:
                case FieldType.CFDsFamiliarityId:
                case FieldType.WarrantsFamiliarityId:
                    const checked: boolean = values[`${fieldType}_checkbox`];
                    if (checked) {
                        return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                    }
                    return true;
            }
        }
        return true;
    }

    private static foundCountry(value: string | string[]): boolean {
        if (value) {
            const kycState: KycState = Platform.state(ServiceType.Kyc);
            if (Array.isArray(value)) {
                if (Utils.isArrayEmpty(value)) {
                    return false;
                }
                let found: boolean = true;
                for (let i = 0; i < value.length; i++) {
                    if (Utils.isNotEmpty(value[i]) && !kycState.getCountryByName(value[i])) {
                        found = false;
                        break;
                    }
                }
                return found;
            } else if (kycState.getCountryByName(value)) {
                return true;
            }
        }
        return false;
    }

    private static validateRegExp<Form>(fieldType: FieldType, pattern: string, values: FormikValues, errors: FormikErrors<Form>): void {
        const value: string = values[fieldType];
        const regExp: RegExp = new RegExp(pattern);
        if (!regExp.test(value)) {
            errors[fieldType] = "Invalid";
        }
    }

    public static validateFormField(fieldType: FieldType, form: ComplianceFormInfo, lastFormPagePassed: boolean, addressExtended: boolean): boolean {
        if (Utils.isNotNull(fieldType)) {
            const value: any = form[fieldType];
            switch (fieldType) {
                case FieldType.FirstName:
                case FieldType.LastName:
                case FieldType.CountryCallingCode:
                case FieldType.PhoneNumber:
                case FieldType.BirthDate:
                case FieldType.PassportNumber:
                case FieldType.TaxIdentificationNumber:
                    return Utils.isNotEmpty(value);
                case FieldType.Address:
                    return Utils.isNotEmpty(value) && (addressExtended ? Utils.isNotEmpty(form[FieldType.City]) && Utils.isNotEmpty(form[FieldType.PostalCode]) : true);
                case FieldType.CountryOfResidence:
                case FieldType.CountryOfBirth:
                case FieldType.Nationality:
                case FieldType.EducationLevel:
                case FieldType.EmploymentStatus:
                case FieldType.PreviousIndustryId:
                case FieldType.PreviousEmploymentDetailsType:
                case FieldType.AnnualIncome:
                case FieldType.SavingsAndInvestments:
                case FieldType.ExperienceTrading:
                case FieldType.TradingVolume:
                case FieldType.TradingFrequency:
                case FieldType.ExperienceInFinancialServices:
                case FieldType.MaxLeveragedAmount:
                case FieldType.TradingSharePrice:
                case FieldType.TradingStopLossOrder:
                case FieldType.MarketRateAmount:
                case FieldType.PotentialProfitOrLoose:
                case FieldType.TradingRiskCFDAmount:
                case FieldType.PurposeOfTrading:
                case FieldType.InvestmentHorizonTypeId:
                case FieldType.PlannedTradingAmountPerYear:
                case FieldType.MethodOfDeposit:
                case FieldType.CountryOfTaxRegistration:
                case FieldType.ExperienceLength:
                    return Utils.greaterThen0(value);
                case FieldType.CountryOfEmployment:
                case FieldType.SourceOfWealth:
                case FieldType.SourceOfTradingFunds:
                    return Utils.isArrayNotEmpty(value);
                case FieldType.AreaOfEducationId:
                    const educationLevelId: number = parseInt(form[FieldType.EducationLevel] as any, 10);
                    if (EducationLevelAnswerEnableAreaOfEducation.indexOf(educationLevelId) > -1) {
                        return Utils.greaterThen0(value);
                    }
                    return true;
                case FieldType.Industry:
                case FieldType.EmploymentDetailsType:
                    const employmentStatusId: number = parseInt(form[FieldType.EmploymentStatus] as any, 10);
                    if (EmploymentStatusAnswerDisableCurrentIndustry.indexOf(employmentStatusId) >= 0) {
                        return true;
                    }
                    return Utils.greaterThen0(value);
                case FieldType.IndustryDescription:
                    const industryAnswer: number = parseInt(form[FieldType.Industry] as any, 10);
                    if (industryAnswer === 30) {
                        return Utils.isNotNull(value) && Utils.isNotEmpty(value.toString());
                    }
                    return true;
                case FieldType.ListedSharesId:
                case FieldType.ExchangeTradedFundsFamiliarityId:
                case FieldType.FinancialDerivativesFamiliarityId:
                case FieldType.CFDsFamiliarityId:
                case FieldType.WarrantsFamiliarityId:
                    if (!lastFormPagePassed) {
                        const allEmpty: boolean = !Utils.greaterThen0(form[FieldType.ListedSharesId])
                            && !Utils.greaterThen0(form[FieldType.ExchangeTradedFundsFamiliarityId])
                            && !Utils.greaterThen0(form[FieldType.FinancialDerivativesFamiliarityId])
                            && !Utils.greaterThen0(form[FieldType.CFDsFamiliarityId])
                            && !Utils.greaterThen0(form[FieldType.WarrantsFamiliarityId]);
                        return !allEmpty;
                    }
                    return true;
            }
        }
        return true;
    }
}
