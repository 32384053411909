import Utils from "platform/util/Utils";

enum ActionsInfoType {

    ShouldGoToFilledOut = 1,
    ShouldGoToInappropriate = 2,
    ShouldGoToDeposit = 3,
    ShouldGoElectronicSignature = 4,
    ShouldGoToDocument = 5,
    ShouldGoToWebProfit = 6,
    ShouldStayAtComplianceOnBoarding = 7,
    ShouldGoToCNMV = 8,
    ShouldGoToInappropriateWithPrint = 9,
    ShouldGoToAppropriate = 10,
    ShouldGoToProductGovernanceQuestions = 11,
    ShouldGoToThankYouMessage = 12,
    ShouldGoToShareInfoWithPrint = 13,
    ShouldGoToFinancialDetailsInternational = 14,
}

namespace ActionsInfoType {

    export function aggressive(action: ActionsInfoType): boolean {
        if (Utils.isNotNull(action)) {
            switch (action) {
                case ActionsInfoType.ShouldGoToCNMV:
                case ActionsInfoType.ShouldGoToFilledOut:
                case ActionsInfoType.ShouldGoToInappropriate:
                case ActionsInfoType.ShouldGoToWebProfit:
                case ActionsInfoType.ShouldGoToInappropriateWithPrint:
                    return true;
            }
        }
        return false;
    }

    export function deserialize(action: string): ActionsInfoType {
        if (Utils.isNotEmpty(action)) {
            switch (action) {
                case "1":
                    return ActionsInfoType.ShouldGoToFilledOut;
                case "2":
                    return ActionsInfoType.ShouldGoToInappropriate;
                case "3":
                    return ActionsInfoType.ShouldGoToDeposit;
                case "4":
                    return ActionsInfoType.ShouldGoElectronicSignature;
                case "5":
                    return ActionsInfoType.ShouldGoToDocument;
                case "6":
                    return ActionsInfoType.ShouldGoToWebProfit;
                case "7":
                    return ActionsInfoType.ShouldStayAtComplianceOnBoarding;
                case "9":
                    return ActionsInfoType.ShouldGoToInappropriateWithPrint;
                case "13":
                    return ActionsInfoType.ShouldGoToShareInfoWithPrint;
                case "14":
                    return ActionsInfoType.ShouldGoToFinancialDetailsInternational;
            }
        }
        return null;
    }
}

export {
    ActionsInfoType
}
