export class DocumentsState {

    private static _instance: DocumentsState;

    private constructor() {
    }

    public static instance(): DocumentsState {
        return this._instance || (this._instance = new this());
    }
}
