import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "kbd/enum/ServiceType";
import KycReducer from "kbd/core/redux/kyc/KycReducer";
import KycEngine from "kbd/core/engine/KycEngine";
import {Dpk} from "platform/dpk/Dpk";
import {KycDpkHandler} from "kbd/core/dpk/KycDpkHandler";
import {KycState} from "kbd/core/state/KycState";

export class KycService implements Service<KycEngine, KycState> {

    private static _instance: KycService;

    private constructor() {
    }

    public static instance(): KycService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
        Dpk.setHandler(new KycDpkHandler());
    }

    public name(): string {
        return ServiceType.Kyc;
    }

    public reducers(): Array<Reducer<any>> {
        return [KycReducer.instance()];
    }

    public engine(): KycEngine {
        return KycEngine.instance();
    }

    public state(): KycState {
        return KycState.instance();
    }
}
