import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TSMap} from "typescript-map";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import {DocumentCreditCard} from "kbd/protocol/response/GetUsedCreditCardsResponse";
import {PageType} from "kbd/enum/PageType";

export const SetDocumentTypesType: string =  "SetDocumentTypes";
export const SetDocumentStatusesType: string =  "SetDocumentStatuses";
export const SetDocumentSubTypeType: string =  "SetDocumentSubType";
export const SetWebCamDocTypeType: string =  "SetWebCamDocType";
export const SetUploadDocType: string =  "SetUploadDoc";
export const SetUploadPhotoType: string =  "SetUploadPhoto";
export const UploadDocumentBatchType: string =  "UploadDocumentBatch";
export const SetWebCamBlockedType: string =  "SetWebCamBlocked";
export const DoCancelDocumentType: string =  "DoCancelDocument";
export const RemoveDocumentContentType: string =  "RemoveDocumentContent";
export const SetUploadDocumentErrorsType: string =  "SetUploadDocumentErrors";
export const SetUploadCancelDocInProgressType: string =  "SetUploadCancelDocInProgress";
export const SetKycCreditCardsType: string =  "SetKycCreditCards";
export const SetWizardDocsNavigationType: string = "SetWizardDocsNavigation";
export const SetWizardDocsIndexType: string = "SetWizardDocsIndex";
export const SetSelectedDocumentTypeType: string = "SetSelectedDocumentType";
export const SetUsedCreditCardsType: string = "SetUsedCreditCards";
export const SetUsedCreditCardUploadedType: string = "SetUsedCreditCardUploaded";
export const SetSelectedCreditCardType: string = "SetSelectedCreditCard";

export interface SetDocumentTypesPayload {

    documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>;
}

export interface SetDocumentStatusesPayload {

    documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>>;
}

export interface SetDocumentSubTypePayload {

    documentType: ProofDocumentType;
    subType: number;
}

export interface SetWebCamDocTypePayload {

    webCamDocType: ProofDocumentType;
    webCamSubDocType: number | string;
    webCamDocIndex: number;
    webCamAdditional: boolean;
}

export interface SetUploadDocPayload {

    docType: ProofDocumentType;
    subDocType: number | string;
    docIndex: number;
    additional: boolean;
    file: File;
}

export interface SetUploadPhotoPayload {

    docType: ProofDocumentType;
    subDocType: number | string;
    docIndex: number;
    additional: boolean;
    photo: string;
    fileName: string;
}

export interface UploadDocumentBatchPayload {

    docType: ProofDocumentType;
    subDocType: number | string;
    nextPage?: PageType;
    fromPage?: PageType;
    wizardIndex?: number;
    nextDocType?: ProofDocumentType
}

export interface SetWebCamBlockedPayload {

    blocked: boolean;
}

export interface DoCancelDocumentPayload {

    Document: DocumentInfo;
}

export interface RemoveDocumentContentPayload {

    docType: ProofDocumentType;
    subDocType: number | string;
}

export interface SetUploadDocumentErrorsPayload {

    docType: ProofDocumentType;
    subDocType: number | string;
    errors: ErrorInfo[];
}

export interface SetUploadCancelDocInProgressPayload {

    inProgress: boolean;
}

export interface SetKycCreditCardsPayload {

    creditCards: DocumentCreditCard[];
}

export interface SetWizardDocsNavigationPayload {

    wizard: ProofDocumentType[];
}

export interface SetWizardDocsIndexPayload {

    index: number;
}

export interface SetSelectedDocumentTypePayload {

    documentType: ProofDocumentType;
}

export interface SetUsedCreditCardsPayload {

    cards: DocumentCreditCard[];
}

export interface SetUsedCreditCardUploadedPayload {

    last4Digits: string;
    uploaded: boolean;
}

export interface SetSelectedCreditCardPayload {

    creditCard: DocumentCreditCard;
}

export const SetDocumentTypes: ActionCreatorDefinition<SetDocumentTypesPayload, any> = createAction<SetDocumentTypesPayload>(SetDocumentTypesType);
export const SetDocumentStatuses: ActionCreatorDefinition<SetDocumentStatusesPayload, any> = createAction<SetDocumentStatusesPayload>(SetDocumentStatusesType);
export const SetDocumentSubType: ActionCreatorDefinition<SetDocumentSubTypePayload, any> = createAction<SetDocumentSubTypePayload>(SetDocumentSubTypeType);
export const SetWebCamDocType: ActionCreatorDefinition<SetWebCamDocTypePayload, any> = createAction<SetWebCamDocTypePayload>(SetWebCamDocTypeType);
export const SetUploadDoc: ActionCreatorDefinition<SetUploadDocPayload, any> = createAction<SetUploadDocPayload>(SetUploadDocType);
export const SetUploadPhoto: ActionCreatorDefinition<SetUploadPhotoPayload, any> = createAction<SetUploadPhotoPayload>(SetUploadPhotoType);
export const UploadDocumentBatch: ActionCreatorDefinition<UploadDocumentBatchPayload, any> = createAction<UploadDocumentBatchPayload>(UploadDocumentBatchType);
export const SetWebCamBlocked: ActionCreatorDefinition<SetWebCamBlockedPayload, any> = createAction<SetWebCamBlockedPayload>(SetWebCamBlockedType);
export const DoCancelDocument: ActionCreatorDefinition<DoCancelDocumentPayload, any> = createAction<DoCancelDocumentPayload>(DoCancelDocumentType);
export const RemoveDocumentContent: ActionCreatorDefinition<RemoveDocumentContentPayload, any> = createAction<RemoveDocumentContentPayload>(RemoveDocumentContentType);
export const SetUploadDocumentErrors: ActionCreatorDefinition<SetUploadDocumentErrorsPayload, any> = createAction<SetUploadDocumentErrorsPayload>(SetUploadDocumentErrorsType);
export const SetUploadCancelDocInProgress: ActionCreatorDefinition<SetUploadCancelDocInProgressPayload, any> = createAction<SetUploadCancelDocInProgressPayload>(SetUploadCancelDocInProgressType);
export const SetKycCreditCards: ActionCreatorDefinition<SetKycCreditCardsPayload, any> = createAction<SetKycCreditCardsPayload>(SetKycCreditCardsType);
export const SetWizardDocsNavigation: ActionCreatorDefinition<SetWizardDocsNavigationPayload, any> = createAction<SetWizardDocsNavigationPayload>(SetWizardDocsNavigationType);
export const SetWizardDocsIndex: ActionCreatorDefinition<SetWizardDocsIndexPayload, any> = createAction<SetWizardDocsIndexPayload>(SetWizardDocsIndexType);
export const SetSelectedDocumentType: ActionCreatorDefinition<SetSelectedDocumentTypePayload, any> = createAction<SetSelectedDocumentTypePayload>(SetSelectedDocumentTypeType);
export const SetUsedCreditCards: ActionCreatorDefinition<SetUsedCreditCardsPayload, any> = createAction<SetUsedCreditCardsPayload>(SetUsedCreditCardsType);
export const SetUsedCreditCardUploaded: ActionCreatorDefinition<SetUsedCreditCardUploadedPayload, any> = createAction<SetUsedCreditCardUploadedPayload>(SetUsedCreditCardUploadedType);
export const SetSelectedCreditCard: ActionCreatorDefinition<SetSelectedCreditCardPayload, any> = createAction<SetSelectedCreditCardPayload>(SetSelectedCreditCardType);
