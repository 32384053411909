import {Engine} from "platform/engine/Engine";
import {
    SetDepositActivePage,
    SetDepositCurrency,
    SetDepositPackages,
    SetDepositStatus,
    SetHasFtd
} from "kbd/core/redux/deposit/DepositReduxActions";
import {DepositReduxState} from "kbd/core/redux/deposit/DepositReduxState";
import Platform from "platform/Platform";
import {StoreState} from "kbd/core/redux/StoreState";
import {PageType} from "kbd/enum/PageType";
import {NavigateToPayload} from "platform/redux/core/CoreActions";
import {Route} from "platform/redux/PlatformReduxState";
import {LoadLanguagePayload} from "platform/redux/translation/TranslationActions";
import {LangCode} from "platform/enum/LangCode";
import {GetConfigurationRequest} from "kbd/protocol/request/GetConfigurationRequest";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {GetConfigurationsResponse} from "kbd/protocol/response/GetConfigurationsResponse";
import {PackageInfo} from "kbd/protocol/PackageInfo";
import {SetAddressExtended} from "kbd/core/redux/kyc/KycReduxActions";
import {Currency} from "platform/enum/Currency";

export default class DepositEngine extends Engine {

    private static _instance: DepositEngine;
    private _configurationReceived: boolean;
    private _correctionInProgress: boolean;

    public static instance(): DepositEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
    }

    public onLoadLanguage = (payload: LoadLanguagePayload): void => {
        const {langCode} = Platform.reduxState().translation;
        if (langCode !== payload.langCode) {
            this.fetchDepositConfiguration(payload.langCode).catch(() => {});
        }
    }

    public onChangeRoute = (payload): void => {
        const route: Route = payload.route;
        this.correctNavigation(route.name as PageType);
    }

    public onNavigateTo = (payload: NavigateToPayload): void => {
        this.correctNavigation(payload.route as PageType);
    }

    private correctNavigation = (pageType: PageType): void => {
        if (pageType === PageType.DepositTopUp) {
            if (!this._correctionInProgress) {
                this._correctionInProgress = true;
                const callback = () => {
                    const deposit: DepositReduxState = Platform.reduxState<StoreState>().deposit;
                    const targetPageType: PageType = deposit.hasOptions && !deposit.hasFTD ? PageType.DepositTopUp : PageType.DepositIFrame;
                    Platform.dispatch(SetDepositActivePage({pageType: targetPageType}));
                    this._correctionInProgress = false;
                };
                const {hasOptions, hasFTD} = Platform.reduxState<StoreState>().deposit;
                this._logger.debug("Start deposit route correction. Config received: " + this._configurationReceived + " hasOptions: " + hasOptions + " hasFTD: " + hasFTD);
                if (!this._configurationReceived || (hasOptions && !hasFTD)) {
                    Platform.dispatch(SetDepositActivePage({pageType: null}));
                    const {langCode} = Platform.reduxState().translation;
                    const request: GetConfigurationRequest = {LanguageCode: langCode};
                    XhrManager.sendToConfig(request, "GetConfigurations").then((response: GetConfigurationsResponse) => {
                        Platform.dispatch(SetDepositStatus({
                            status: {
                                hasFTD: response.HasFTD,
                                hasOptions: Utils.isArrayNotEmpty(response.Packages),
                                applyOptionDescriptionHeight: false,
                                maxOptionDescriptionHeight: 0,
                                optionDescriptionCounter: 0
                            }
                        }));
                        callback();
                    }).catch(callback);
                } else {
                    callback();
                }
            }
        }
    }

    private fetchDepositConfiguration = async (langCode: LangCode) => {
        this._logger.debug("Fetch deposit configuration");
        const request: GetConfigurationRequest = {LanguageCode: langCode};
        const answer: [HttpReject, GetConfigurationsResponse] = await Utils.to(XhrManager.sendToConfig(request, "GetConfigurations"));
        if (answer[0]) {
            this._logger.debug("Failed fetch proof doc types. Status: " + answer[0].status);
        } else {
            const {AssetName, IsAutoCompleteEnabled, HasFTD, Packages} = answer[1];
            this._logger.debug("Received configuration. IsAutoCompleteEnabled: " + IsAutoCompleteEnabled + " HasFTD: " + HasFTD);
            Platform.dispatch(SetHasFtd({hasFtd: HasFTD}));
            Platform.dispatch(SetDepositCurrency({currency: AssetName as Currency}));
            if (Utils.isNull(Platform.reduxState<StoreState>().kyc.addressExtended) || !IsAutoCompleteEnabled) {
                Platform.dispatch(SetAddressExtended({
                    extended: !IsAutoCompleteEnabled
                }));
            }
            if (Utils.isArrayNotEmpty(Packages)) {
                const packages: PackageInfo[] = Packages.sort((p1: PackageInfo, p2: PackageInfo) => Utils.compareNumber(p1.Position, p2.Position))
                    .filter((packageInfo: PackageInfo) => Utils.greaterThen0(packageInfo.Amount));
                if (Utils.isArrayNotEmpty(packages)) {
                    Platform.dispatch(SetDepositPackages({packages}));
                }
            }
            this._configurationReceived = true;
        }
    }
}
