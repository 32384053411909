import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";

export enum IntegrationOperation {

    CloseInappropriatePopupWindow = "CloseInappropriatePopupWindow",
}

export class IntegrationExecuteOperation implements IntegrationMessage {

    public classId: number = null;
    public operation: IntegrationOperation;

    constructor(operation: IntegrationOperation) {
        this.operation = operation;
    }
}
