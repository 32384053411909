import {FieldType} from "kbd/enum/FieldType";
import Utils from "platform/util/Utils";
import {KycState} from "kbd/core/state/KycState";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {FormikValues} from "formik";

export class FormikAdapter {

    private constructor() {}

    public static fromFormik(fieldType: FieldType, value: any, form: any): any {
        if (Utils.isNotNull(fieldType) && Utils.isNotNull(value)) {
            const kycState: KycState = Platform.state(ServiceType.Kyc);
            switch (fieldType) {
                case FieldType.EducationLevel:
                case FieldType.AreaOfEducationId:
                case FieldType.EmploymentStatus:
                case FieldType.AnnualIncome:
                case FieldType.Industry:
                case FieldType.EmploymentDetailsType:
                case FieldType.PreviousIndustryId:
                case FieldType.PreviousEmploymentDetailsType:
                case FieldType.SavingsAndInvestments:
                case FieldType.PurposeOfTrading:
                case FieldType.InvestmentHorizonTypeId:
                case FieldType.PlannedTradingAmountPerYear:
                case FieldType.MethodOfDeposit:
                case FieldType.ExperienceLength:
                    return parseInt(value, 10);
                case FieldType.SourceOfWealth:
                case FieldType.SourceOfTradingFunds:
                    const ids: number[] = [];
                    (value as string[]).forEach((id: string) => {
                        const nId: number = parseInt(id, 10);
                        if (nId) {
                            ids.push(nId);
                        }
                    });
                    return ids;
                case FieldType.CountryOfResidence:
                case FieldType.CountryOfBirth:
                case FieldType.Nationality:
                case FieldType.CountryOfTaxRegistration:
                    const ci: CountryInfo = kycState.getCountryByName(value);
                    return ci ? ci.Id : null;
                case FieldType.CountryOfEmployment:
                    const countryIds: number[] = [];
                    (value as string[]).forEach((name: string) => {
                        const countryInfo: CountryInfo = kycState.getCountryByName(name);
                        if (countryInfo) {
                            countryIds.push(countryInfo.Id);
                        }
                    });
                    return countryIds;
                case FieldType.ListedSharesId:
                case FieldType.ExchangeTradedFundsFamiliarityId:
                case FieldType.FinancialDerivativesFamiliarityId:
                case FieldType.CFDsFamiliarityId:
                case FieldType.WarrantsFamiliarityId:
                    if (form[`${fieldType}_checkbox`]) {
                        return parseInt(value, 10);
                    }
                    return null;
            }
        }
        return value;
    }

    public static toFormik(fieldType: FieldType, value: any, form: FormikValues): any {
        if (Utils.isNotNull(fieldType)) {
            if (Utils.isNotNull(value)) {
                const kycState: KycState = Platform.state(ServiceType.Kyc);
                switch (fieldType) {
                    case FieldType.CountryOfResidence:
                    case FieldType.CountryOfBirth:
                    case FieldType.Nationality:
                    case FieldType.CountryOfTaxRegistration:
                        const ci: CountryInfo = kycState.getCountry(value);
                        return ci ? ci.LocalizedName : "";
                    case FieldType.CountryOfEmployment:
                        const ids: number[] = value as number[];
                        if (Utils.isArrayNotEmpty(ids)) {
                            return ids.map((id: number) => {
                                const cI: CountryInfo = kycState.getCountry(id);
                                return cI ? cI.LocalizedName : "";
                            });
                        } else {
                            return null;
                        }
                    case FieldType.SourceOfWealth:
                    case FieldType.SourceOfTradingFunds:
                        return Utils.isArrayNotEmpty(value as number[]) ? value : null;
                    case FieldType.ListedSharesId:
                    case FieldType.ExchangeTradedFundsFamiliarityId:
                    case FieldType.FinancialDerivativesFamiliarityId:
                    case FieldType.CFDsFamiliarityId:
                    case FieldType.WarrantsFamiliarityId:
                        if (Utils.greaterThen0(value)) {
                            form[`${fieldType}_checkbox`] = true;
                        }
                        break;
                }
            } else if (fieldType === FieldType.UsCitizen || fieldType === FieldType.PoliticalExposedPerson) {
                return false;
            }
        }
        return value;
    }
}
