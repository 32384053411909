import {TSMap} from "typescript-map";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {PageType} from "kbd/enum/PageType";

export class KycState {

    private static _instance: KycState;
    private _countries: TSMap<number, CountryInfo> = new TSMap();
    private _namePerCountry: TSMap<string, CountryInfo> = new TSMap();
    private _phoneCodePerCountry: TSMap<string, CountryInfo> = new TSMap();
    private _hasAnswersForPage: TSMap<PageType, boolean> = new TSMap();

    private constructor() {
    }

    public static instance(): KycState {
        return this._instance || (this._instance = new this());
    }

    public addCountry(ci: CountryInfo): void {
        this._countries.set(ci.Id, ci);
        this._phoneCodePerCountry.set(ci.PhoneCode, ci);
        if (ci.LocalizedName) {
            this._namePerCountry.set(ci.LocalizedName.toLowerCase(), ci);
        }
    }

    public getCountry(id: number): CountryInfo {
        return this._countries.get(id);
    }

    public getCountryByName(name: string): CountryInfo {
        return this._namePerCountry.get(name ? name.toLowerCase() : "");
    }

    public getCountryByPhoneCode(phoneCode: string): CountryInfo {
        return this._phoneCodePerCountry.get(phoneCode);
    }

    public clearCountries(): void {
        this._countries.clear();
        this._namePerCountry.clear();
        this._phoneCodePerCountry.clear();
    }

    public setHasAnswersForPage(pageType: PageType): void {
        this._hasAnswersForPage.set(pageType, true);
    }

    public hasAnswersForPage(pageType: PageType): boolean {
        return this._hasAnswersForPage.get(pageType);
    }
}
