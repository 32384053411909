import Utils from "platform/util/Utils";

enum ActionType {

    None = "none",
    Navigate = "navigate",
}

namespace ActionType {

    export function deserialize(v: string): ActionType {
        if (Utils.isNotEmpty(v)) {
            const keys: string[] = Object.keys(ActionType);
            for (let i = 0; i < keys.length; i++) {
                if (ActionType[keys[i]] === v) {
                    return v as ActionType;
                }
            }
        }
        return ActionType.None;
    }
}

export {
    ActionType
};
