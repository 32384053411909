import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {
    DepositReduxState,
} from "kbd/core/redux/deposit/DepositReduxState";
import DepositEngine from "kbd/core/engine/DepositEngine";
import {PageType} from "kbd/enum/PageType";
import {
    ResetTopUpOptionDescriptionHeight,
    SetDepositActivePage,
    SetDepositActivePagePayload,
    SetDepositAmount,
    SetDepositAmountPayload,
    SetDepositCurrency,
    SetDepositCurrencyPayload,
    SetDepositOption,
    SetDepositOptionPayload,
    SetDepositPackages,
    SetDepositPackagesPayload,
    SetDepositStatus,
    SetDepositStatusPayload,
    SetHasFtd,
    SetHasFtdPayload,
    SetTopUpOptionDescriptionHeight,
    SetTopUpOptionDescriptionHeightPayload,
} from "kbd/core/redux/deposit/DepositReduxActions";
import Utils from "platform/util/Utils";
import {NavigateToType} from "platform/redux/core/CoreActions";
import {Currency} from "platform/enum/Currency";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";

export default class DepositReducer extends Reducer<DepositReduxState> {

    private static _instance: DepositReducer;

    public static instance(): DepositReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const depositEngine: DepositEngine = Platform.engine(ServiceType.Deposit);
        this._middlewareActions.set("@@router5/TRANSITION_START", depositEngine.onChangeRoute);
        this._middlewareActions.set(LoadLanguageType, depositEngine.onLoadLanguage);
        this._middlewareActions.set(NavigateToType, depositEngine.onNavigateTo);
    }

    public get name(): string {
        return "deposit";
    }

    protected setup(builder: ReducerBuilder<DepositReduxState>): void {
        builder
            .init({
                packages: [],
                currency: Currency.EUR,
                hasOptions: false,
                activeTopUpPage: PageType.DepositIFrame,
                optionDescriptionCounter: 0,
                maxOptionDescriptionHeight: 0
            })
            .handle(SetDepositStatus, (state: DepositReduxState, {payload}: Action<SetDepositStatusPayload>) => {
                return Object.assign({}, state, payload.status);
            })
            .handle(SetDepositActivePage, (state: DepositReduxState, {payload}: Action<SetDepositActivePagePayload>) => {
                return Object.assign({}, state, {
                    activeTopUpPage: payload.pageType
                });
            })
            .handle(SetDepositPackages, (state: DepositReduxState, {payload}: Action<SetDepositPackagesPayload>) => {
                const hasOptions: boolean = Utils.isArrayNotEmpty(payload.packages);
                return Object.assign({}, state, {
                    packages: payload.packages || [],
                    hasOptions,
                    activeTopUpPage: hasOptions ? PageType.DepositTopUp : PageType.DepositIFrame
                });
            })
            .handle(SetHasFtd, (state: DepositReduxState, {payload}: Action<SetHasFtdPayload>) => {
                return Object.assign({}, state, {
                    hasFTD: payload.hasFtd
                });
            })
            .handle(SetDepositOption, (state: DepositReduxState, {payload}: Action<SetDepositOptionPayload>) => {
                return Object.assign({}, state, {
                    topUpAmount: payload.amount,
                });
            })
            .handle(SetDepositAmount, (state: DepositReduxState, {payload}: Action<SetDepositAmountPayload>) => {
                return Object.assign({}, state, {
                    topUpAmount: payload.amount,
                });
            })
            .handle(SetDepositCurrency, (state: DepositReduxState, {payload}: Action<SetDepositCurrencyPayload>) => {
                return Object.assign({}, state, {
                    currency: payload.currency,
                });
            })
            .handle(SetTopUpOptionDescriptionHeight, (state: DepositReduxState, {payload}: Action<SetTopUpOptionDescriptionHeightPayload>) => {
                const optionDescriptionCounter: number = state.optionDescriptionCounter + 1;
                let maxOptionDescriptionHeight: number = state.maxOptionDescriptionHeight;
                if (maxOptionDescriptionHeight < payload.height) {
                    maxOptionDescriptionHeight = payload.height;
                }
                return Object.assign({}, state, {
                    optionDescriptionCounter,
                    maxOptionDescriptionHeight,
                    applyOptionDescriptionHeight: optionDescriptionCounter === state.packages.length
                });
            })
            .handle(ResetTopUpOptionDescriptionHeight, (state: DepositReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    optionDescriptionCounter: 0
                });
            });
    }
}
