import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "kbd/enum/ServiceType";
import DepositLimitsEngine from "kbd/core/engine/DepositLimitsEngine";
import DepositLimitsReducer from "kbd/core/redux/limits/DepositLimitsReducer";

export class DepositLimitsService implements Service<DepositLimitsEngine, any> {

    private static _instance: DepositLimitsService;

    private constructor() {
    }

    public static instance(): DepositLimitsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.DepositLimits;
    }

    public reducers(): Array<Reducer<any>> {
        return [DepositLimitsReducer.instance()];
    }

    public engine(): DepositLimitsEngine {
        return DepositLimitsEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
