export enum ProofIdDocumentType {
    //
    // Summary:
    //     Passport.
    Passport = 1,
    //
    // Summary:
    //     National Id card.
    NationalIdCard = 2,
    //
    // Summary:
    //     Driver's license.
    DriversLicense = 3,
    //
    // Summary:
    //     Other.
    Other = 4
}
