import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {
    DocumentPayload,
    DocumentPayloadType,
    DocumentsReduxState
} from "kbd/core/redux/documents/DocumentsReduxState";
import DocumentsEngine from "kbd/core/engine/DocumentsEngine";
import {
    RemoveDocumentContent,
    SetDocumentStatuses,
    SetDocumentTypes,
    SetUploadDoc,
    SetUploadCancelDocInProgress,
    SetUploadDocumentErrors,
    SetUploadPhoto,
    SetWebCamBlocked,
    SetWebCamDocType,
    SetKycCreditCards,
    DoCancelDocumentType,
    SetDocumentTypesPayload,
    SetDocumentStatusesPayload,
    SetUploadDocPayload,
    SetUploadPhotoPayload,
    RemoveDocumentContentPayload,
    SetWebCamDocTypePayload,
    SetWebCamBlockedPayload,
    SetUploadDocumentErrorsPayload,
    SetUploadCancelDocInProgressPayload,
    SetDocumentSubType,
    SetDocumentSubTypePayload,
    SetSelectedCreditCard,
    SetSelectedCreditCardPayload,
    SetKycCreditCardsPayload,
    SetSelectedDocumentType,
    SetSelectedDocumentTypePayload,
    SetUsedCreditCards,
    SetUsedCreditCardsPayload,
    SetWizardDocsNavigation,
    SetWizardDocsNavigationPayload,
    SetWizardDocsIndex,
    SetWizardDocsIndexPayload,
    SetUsedCreditCardUploaded,
    SetUsedCreditCardUploadedPayload,
    UploadDocumentBatchType
} from "kbd/core/redux/documents/DocumentsReduxActions";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {TSMap} from "typescript-map";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import {ProofIdDocumentType} from "kbd/protocol/ProofIdDocumentType";
import {ResidenceProofDocumentType} from "kbd/protocol/ResidenceProofDocumentType";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import {FileType} from "kbd/enum/FileType";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";

export default class DocumentsReducer extends Reducer<DocumentsReduxState> {

    private static _instance: DocumentsReducer;

    public static instance(): DocumentsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const documentsEngine: DocumentsEngine = Platform.engine(ServiceType.Documents);
        this._middlewareActions.set("@@router5/TRANSITION_START", documentsEngine.onChangeRoute);
        this._middlewareActions.set(LoadLanguageType, documentsEngine.onLoadLanguage);
        this._middlewareActions.set(DoCancelDocumentType, documentsEngine.doCancelDocument);
        this._middlewareActions.set(UploadDocumentBatchType, documentsEngine.doUploadDocumentBatch);
    }

    public get name(): string {
        return "documents";
    }

    protected setup(builder: ReducerBuilder<DocumentsReduxState>): void {
        const documentSubTypes: TSMap<ProofDocumentType, number> = new TSMap();
        documentSubTypes.set(ProofDocumentType.ProofOfId, ProofIdDocumentType.NationalIdCard);
        documentSubTypes.set(ProofDocumentType.ProofOfResidence, ResidenceProofDocumentType.UtilityBill);
        builder
            .init({
                documentTypes: new TSMap<ProofDocumentType, ProofDocumentTypeInfo>(),
                documentStatuses: new TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>>(),
                documentContents: new TSMap<ProofDocumentType, TSMap<number | string, DocumentPayload[]>>(),
                documentSubTypes,
                errors: new TSMap<ProofDocumentType, TSMap<number | string, ErrorInfo[]>>(),
                creditCards: [],
                creditCardsUploaded: new TSMap<string, boolean>(),
                kycCreditCards: [],
                wizardDocsNavigation: [],
                wizardDocsIndex: 0
            })
            .handle(SetDocumentSubType, (state: DocumentsReduxState, {payload}: Action<SetDocumentSubTypePayload>) => {
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.documentSubTypes.set(payload.documentType, payload.subType);
                return newState;
            })
            .handle(SetDocumentTypes, (state: DocumentsReduxState, {payload}: Action<SetDocumentTypesPayload>) => {
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.documentTypes = payload.documentTypes;
                return newState;
            })
            .handle(SetDocumentStatuses, (state: DocumentsReduxState, {payload}: Action<SetDocumentStatusesPayload>) => {
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.documentStatuses = payload.documentStatuses;
                return newState;
            })
            .handle(SetUploadDoc, (state: DocumentsReduxState, {payload}: Action<SetUploadDocPayload>) => {
                const {docType, subDocType, docIndex, additional, file} = payload;
                this._logger.debug("Set doc file for docType: " + docType + " subDocType: " + subDocType + " docIndex: " + docIndex);
                const documentPayload: DocumentPayload = {
                    payloadType: DocumentPayloadType.File,
                    payload: file,
                    fileType: file.type as FileType,
                    fileName: file.name,
                    additional
                };
                const newState: DocumentsReduxState = Utils.merge({}, state);
                const oldPayloads: TSMap<number | string, DocumentPayload[]> = newState.documentContents.get(docType) || new TSMap();
                const payloads: TSMap<number | string, DocumentPayload[]> = oldPayloads.clone();
                newState.documentContents.set(docType, payloads);
                const oldDocPayloads: DocumentPayload[] = payloads.get(subDocType);
                const docPayloads: DocumentPayload[] = oldDocPayloads ? [...payloads.get(subDocType)] : [];
                payloads.set(subDocType, docPayloads);
                docPayloads[docIndex] = documentPayload;
                return newState;
            })
            .handle(SetUploadPhoto, (state: DocumentsReduxState, {payload}: Action<SetUploadPhotoPayload>) => {
                const {docType, subDocType, docIndex, additional, photo, fileName} = payload;
                this._logger.debug("Set doc photo for docType: " + docType + " subDocType: " + subDocType + " docIndex: " + docIndex);
                const documentPayload: DocumentPayload = {
                    payloadType: DocumentPayloadType.Photo,
                    payload: photo,
                    fileType: FileType.JPEG,
                    fileName,
                    additional
                };
                const newState: DocumentsReduxState = Utils.merge({}, state);
                const oldPayloads: TSMap<number | string, DocumentPayload[]> = newState.documentContents.get(docType) || new TSMap();
                const payloads: TSMap<number | string, DocumentPayload[]> = oldPayloads.clone();
                newState.documentContents.set(docType, payloads);
                const oldDocPayloads: DocumentPayload[] = payloads.get(subDocType);
                const docPayloads: DocumentPayload[] = oldDocPayloads ? [...payloads.get(subDocType)] : [];
                payloads.set(subDocType, docPayloads);
                docPayloads[docIndex] = documentPayload;
                return newState;
            })
            .handle(RemoveDocumentContent, (state: DocumentsReduxState, {payload}: Action<RemoveDocumentContentPayload>) => {
                const {docType, subDocType} = payload;
                this._logger.debug("Remove payloads for: " + docType + " subDocType: " + subDocType);
                const newState: DocumentsReduxState = Utils.merge({}, state);
                const oldPayloads: TSMap<number | string, DocumentPayload[]> = newState.documentContents.get(docType) || new TSMap();
                const payloads: TSMap<number | string, DocumentPayload[]> = oldPayloads.clone();
                newState.documentContents.set(docType, payloads);
                payloads.set(subDocType, []);
                return newState;
            })
            .handle(SetWebCamDocType, (state: DocumentsReduxState, {payload}: Action<SetWebCamDocTypePayload>) => {
                this._logger.debug("Set web cam doc type: " + payload.webCamDocType + " Sub type: " + payload.webCamSubDocType);
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.webCamDocType = payload.webCamDocType;
                newState.webCamSubDocType = payload.webCamSubDocType;
                newState.webCamDocIndex = payload.webCamDocIndex;
                newState.webCamAdditional = payload.webCamAdditional;
                return newState;
            })
            .handle(SetWebCamBlocked, (state: DocumentsReduxState, {payload}: Action<SetWebCamBlockedPayload>) => {
                this._logger.debug("Set web cam blocked: " + payload.blocked);
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.webCamDocType = null;
                newState.webCamSubDocType = null;
                newState.webCamDocIndex = null;
                newState.webCamAdditional = null;
                newState.webCamBlocked = payload.blocked;
                return newState;
            })
            .handle(SetUploadDocumentErrors, (state: DocumentsReduxState, {payload}: Action<SetUploadDocumentErrorsPayload>) => {
                const newState: DocumentsReduxState = Utils.merge({}, state);
                const oldErrors: TSMap<number | string, ErrorInfo[]> = newState.errors.get(payload.docType) || new TSMap();
                const errors: TSMap<number | string, ErrorInfo[]> = oldErrors.clone();
                newState.errors.set(payload.docType, errors);
                errors.set(payload.subDocType, payload.errors);
                return newState;
            })
            .handle(SetUploadCancelDocInProgress, (state: DocumentsReduxState, {payload}: Action<SetUploadCancelDocInProgressPayload>) => {
                return Object.assign({}, state, {
                    uploadCancelInProgress: payload.inProgress
                });
            })
            .handle(SetKycCreditCards, (state: DocumentsReduxState, {payload}: Action<SetKycCreditCardsPayload>) => {
                return Object.assign({}, state, {
                    kycCreditCards: payload.creditCards
                });
            })
            .handle(SetWizardDocsNavigation, (state: DocumentsReduxState, {payload}: Action<SetWizardDocsNavigationPayload>) => {
                return Object.assign({}, state, {
                    wizardDocsIndex: 0,
                    wizardDocsNavigation: payload.wizard,
                    selectedDocumentType: payload.wizard[0]
                });
            })
            .handle(SetWizardDocsIndex, (state: DocumentsReduxState, {payload}: Action<SetWizardDocsIndexPayload>) => {
                return Object.assign({}, state, {
                    wizardDocsIndex: payload.index,
                    selectedDocumentType: state.wizardDocsNavigation[payload.index]
                });
            })
            .handle(SetSelectedDocumentType, (state: DocumentsReduxState, {payload}: Action<SetSelectedDocumentTypePayload>) => {
                return Object.assign({}, state, {
                    selectedDocumentType: payload.documentType
                });
            })
            .handle(SetUsedCreditCards, (state: DocumentsReduxState, {payload}: Action<SetUsedCreditCardsPayload>) => {
                return Object.assign({}, state, {
                    creditCards: payload.cards
                });
            })
            .handle(SetUsedCreditCardUploaded, (state: DocumentsReduxState, {payload}: Action<SetUsedCreditCardUploadedPayload>) => {
                const newState: DocumentsReduxState = Utils.merge({}, state);
                newState.creditCardsUploaded.set(payload.last4Digits, payload.uploaded);
                return newState;
            })
            .handle(SetSelectedCreditCard, (state: DocumentsReduxState, {payload}: Action<SetSelectedCreditCardPayload>) => {
                return Object.assign({}, state, {
                    selectedCreditCard: payload.creditCard
                });
            });
    }
}
