import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {KycReduxState, KycStep} from "kbd/core/redux/kyc/KycReduxState";
import {
    DoGetTinDocUrlType,
    DoGetURLByTypeType,
    DoRecordPrintClickType,
    DoRegisterActivityType, DoReportAgreeWithAppropriateType,
    DoReportAgreeWithInAppropriateType,
    DoReportCNMVType,
    DoSaveKycFormType,
    DoSetSignature, DoSetSignaturePayload, DoSetSignatureType,
    FetchAnswersForQuestionsType,
    GoToTradingPlatformType, SetAcknowledgePoliticalExposedDescription, SetAcknowledgeTINDescription,
    SetAddressExtended, SetAddressExtendedPayload,
    SetAnswersForQuestions, SetAnswersForQuestionsPayload,
    SetBirthCountries,
    SetBirthCountriesPayload,
    SetClickFormSubmit, SetClickFormSubmitPayload,
    SetCnmvState, SetCnmvStatePayload,
    SetConfiguredCountries, SetConfiguredCountriesPayload,
    SetDocumentsStandalone,
    SetFailedToInit, SetFailedToInitPayload, SetFinancialDetailsType, SetFinancialDetailsTypePayload, SetHasSignature,
    SetKycFinished,
    SetKycForm, SetKycFormPayload,
    SetKycStarted,
    SetKycSteps, SetKycStepsPayload,
    SetKycStepStatus, SetKycStepStatusPayload, SetKycStepsType,
    SetLeaveConfirmation, SetLeaveConfirmationPayload, SetSignatureApproved, SetSignatureApprovedPayload,
    SetSubmitFormError, SetSubmitFormErrorPayload,
    SetTinDocUrl, SetTinDocUrlPayload,
    SetURLByType, SetURLByTypePayload,
    SkipIdentityVerificationType,
    StartKyc, StartKycType,
    SubmittingForm, SubmittingFormPayload, UpdateKycSteps, UpdateKycStepsPayload,
    UsedGoogleAutoComplete, UsedGoogleAutoCompletePayload
} from "kbd/core/redux/kyc/KycReduxActions";
import Utils from "platform/util/Utils";
import {TSMap} from "typescript-map";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import KycEngine from "kbd/core/engine/KycEngine";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import {KycStepType} from "kbd/enum/KycStepType";
import {
    DoFinishTopUpType,
    SkipDepositType
} from "kbd/core/redux/deposit/DepositReduxActions";
import {kycStepPassed} from "kbd/core/util/KycUtil";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {SetAppReadyType} from "platform/redux/core/CoreActions";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";
import {QuestionKey} from "kbd/protocol/QuestionKey";
import {AnswerInfo} from "kbd/protocol/AnswerInfo";
import {SelectValue} from "kbd/component/primitive/TTSelect";
import WebUtil from "platform/util/WebUtil";

const SkipWelcome: boolean = Utils.parseBoolean(WebUtil.urlParam("skipWelcome"));
const QuestionAnswersFilters: TSMap<QuestionKey, number[]> = new TSMap();
QuestionAnswersFilters.set(QuestionKey.EmploymentDetailsType, [7, 11, 15, 16, 17, 18, 19, 20, 21]);
QuestionAnswersFilters.set(QuestionKey.PreviousEmploymentDetailsType, [7, 11, 15, 16, 17, 18, 19, 20, 21]);

export default class KycReducer extends Reducer<KycReduxState> {

    private static _instance: KycReducer;

    public static instance(): KycReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const kycEngine: KycEngine = Platform.engine(ServiceType.Kyc);
        this._middlewareActions.set("@@router5/TRANSITION_START", kycEngine.onChangeRoute);
        this._middlewareActions.set(SetAppReadyType, kycEngine.onAppReady);
        this._middlewareActions.set(LoadLanguageType, kycEngine.onLoadLanguage);
        this._middlewareActions.set(DoGetTinDocUrlType, kycEngine.doGetTinDocUrl);
        this._middlewareActions.set(DoGetURLByTypeType, kycEngine.doGetURLByType);
        this._middlewareActions.set(FetchAnswersForQuestionsType, kycEngine.doFetchAnswersForQuestions);
        this._middlewareActions.set(SetKycStepsType, kycEngine.setKycSteps);
        this._middlewareActions.set(StartKycType, kycEngine.doStarKyc);
        this._middlewareActions.set(DoSaveKycFormType, kycEngine.doSaveKycForm);
        this._middlewareActions.set(SkipDepositType, kycEngine.doSkipDeposit);
        this._middlewareActions.set(DoFinishTopUpType, kycEngine.onFinishTopUp);
        this._middlewareActions.set(SkipIdentityVerificationType, kycEngine.doSkipIdentityVerification);
        this._middlewareActions.set(GoToTradingPlatformType, kycEngine.goToTradingPlatform);
        this._middlewareActions.set(DoRegisterActivityType, kycEngine.doRegisterActivity);
        this._middlewareActions.set(DoReportCNMVType, kycEngine.doReportCNMV);
        this._middlewareActions.set(DoRecordPrintClickType, kycEngine.doRecordPrintClick);
        this._middlewareActions.set(DoReportAgreeWithAppropriateType, kycEngine.doReportAgreeWithAppropriate);
        this._middlewareActions.set(DoReportAgreeWithInAppropriateType, kycEngine.doReportAgreeWithInAppropriate);
        this._middlewareActions.set(DoSetSignatureType, kycEngine.doSetSignature);
    }

    public get name(): string {
        return "kyc";
    }

    protected setup(builder: ReducerBuilder<KycReduxState>): void {
        builder
            .init({
                complianceForm: {
                },
                failedToInit: true,
                hasSignature: false,
                countries: new TSMap(),
                namePerCountry: new TSMap(),
                answers: new TSMap(),
                urls: new TSMap(),
                clickFormSubmit: new TSMap(),
                steps: new TSMap(),
                completedSteps: 0,
                cnvmState: {
                    retypeText: Translations.text(TranslationKey.kycInAppropriateRetypeText)
                },
                birthCountries: new TSMap(),
            })
            .handle(SetConfiguredCountries, (state: KycReduxState, {payload}: Action<SetConfiguredCountriesPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.countries = payload.countries;
                newState.namePerCountry = payload.namePerCountry;
                return newState;
            })
            .handle(SetBirthCountries, (state: KycReduxState, {payload}: Action<SetBirthCountriesPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.birthCountries = payload.countries;
                return newState;
            })
            .handle(SetTinDocUrl, (state: KycReduxState, {payload}: Action<SetTinDocUrlPayload>) => {
                return Object.assign({}, state, {
                    tinDocUrl: payload.url
                });
            })
            .handle(SetURLByType, (state: KycReduxState, {payload}: Action<SetURLByTypePayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.urls.set(payload.urlType, payload.url);
                return newState;
            })
            .handle(SetAnswersForQuestions, (state: KycReduxState, {payload}: Action<SetAnswersForQuestionsPayload>) => {
                if (!Utils.isObjectEmpty(payload.answers)) {
                    const newState: KycReduxState = Utils.merge({}, state);
                    const keys: string[] = Object.keys(payload.answers);
                    keys.forEach((key: QuestionKey) => {
                        let answers: AnswerInfo[] = payload.answers[key];
                        let options: Array<SelectValue<number>> = [];
                        if (answers) {
                            const allowedAnswers: number[] = QuestionAnswersFilters.get(key);
                            if (Utils.isArrayNotEmpty(allowedAnswers)) {
                                answers = answers.filter((answer: AnswerInfo) => allowedAnswers.indexOf(answer.Id) > -1);
                            }
                            options = answers.map((answer: AnswerInfo) => {
                                return {
                                    title: answer.LocalizedAnswer,
                                    value: answer.Id
                                };
                            });
                        }
                        newState.answers.set(key, options);
                    });
                    return newState;
                }
                return state;
            })
            .handle(SetLeaveConfirmation, (state: KycReduxState, {payload}: Action<SetLeaveConfirmationPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.leaveConfirmation = payload.tryToLeave;
                return newState;
            })
            .handle(SetAddressExtended, (state: KycReduxState, {payload}: Action<SetAddressExtendedPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.addressExtended = payload.extended;
                return newState;
            })
            .handle(SetDocumentsStandalone, (state: KycReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    documentsStandalone: true
                });
            })
            .handle(SetKycForm, (state: KycReduxState, {payload}: Action<SetKycFormPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.IsAppropriate = payload.IsAppropriate;
                if (Utils.isNotNull(payload.complianceForm)) {
                    newState.submitting = false;
                    newState.complianceForm = Object.assign({}, state.complianceForm, payload.complianceForm);
                }
                return newState;
            })
            .handle(UsedGoogleAutoComplete, (state: KycReduxState, {payload}: Action<UsedGoogleAutoCompletePayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.complianceForm = Object.assign({}, state.complianceForm, {
                    IsAddressSubmittedWithAutoComplete: payload.used
                });
                return newState;
            })
            .handle(SetKycSteps, (state: KycReduxState, {payload}: Action<SetKycStepsPayload>) => {
                let completedSteps: number = 0;
                const newState: KycReduxState = Utils.merge({}, state);
                newState.steps = new TSMap();
                if (Utils.isArrayNotEmpty(payload.steps)) {
                    payload.steps.forEach((step: KycStep) => {
                        newState.steps.set(step.type, step);
                        if (kycStepPassed(step)) {
                            completedSteps++;
                        }
                    });
                }
                if (!((Utils.isNull(SkipWelcome) || !SkipWelcome) && PageNavigator.isKycFormPage(payload.page.type))) {
                    newState.kycStarted = true;
                }
                newState.lastFormPage = payload.page.type;
                newState.completedSteps = completedSteps;
                return newState;
            })
            .handle(UpdateKycSteps, (state: KycReduxState, {payload}: Action<UpdateKycStepsPayload>) => {
                let completedSteps: number = 0;
                const newState: KycReduxState = Utils.merge({}, state);
                newState.steps = payload.steps;
                payload.steps.forEach((step: KycStep) => {
                    if (kycStepPassed(step)) {
                        completedSteps++;
                    }
                });
                newState.completedSteps = completedSteps;
                return newState;
            })
            .handle(SetKycStepStatus, (state: KycReduxState, {payload}: Action<SetKycStepStatusPayload>) => {
                if (Utils.isNotNull(payload.stepType)) {
                    const newState: KycReduxState = Utils.merge({}, state);
                    const step: KycStep = {...(newState.steps.get(payload.stepType) || {})};
                    step.type = payload.stepType;
                    step.status = payload.status;
                    newState.steps.set(payload.stepType, step);
                    newState.completedSteps = newState.steps.values().filter((kycStep: KycStep) => kycStepPassed(kycStep)).length;
                    return newState;
                }
                return state;
            })
            .handle(StartKyc, (state: KycReduxState, action: Action<any>) => {
                const firstKycStep: KycStepType = PageNavigator.firstKycStep();
                const newState: KycReduxState = Utils.merge({}, state);
                const step: KycStep = {...(newState.steps.get(firstKycStep) || {})};
                if (Utils.isNull(step.status)) {
                    step.type = firstKycStep;
                    step.status = KycStepStatus.InProgress;
                }
                newState.kycStarted = true;
                newState.steps.set(firstKycStep, step);
                return newState;
            })
            .handle(SetFailedToInit, (state: KycReduxState, {payload}: Action<SetFailedToInitPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.failedToInit = payload.failed;
                return newState;
            })
            .handle(SetKycStarted, (state: KycReduxState, action: Action<any>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.kycStarted = true;
                return newState;
            })
            .handle(SetKycFinished, (state: KycReduxState, action: Action<any>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.kycStarted = true;
                newState.kycFinished = true;
                return newState;
            })
            .handle(SetClickFormSubmit, (state: KycReduxState, {payload}: Action<SetClickFormSubmitPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.clickFormSubmit.set(payload.pageType, payload.submit);
                return newState;
            })
            .handle(SubmittingForm, (state: KycReduxState, {payload}: Action<SubmittingFormPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.submitting = payload.submitting;
                return newState;
            })
            .handle(SetSubmitFormError, (state: KycReduxState, {payload}: Action<SetSubmitFormErrorPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.submitError = payload.error;
                return newState;
            })
            .handle(SetCnmvState, (state: KycReduxState, {payload}: Action<SetCnmvStatePayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.cnvmState = Object.assign({}, state.cnvmState, payload.state);
                return newState;
            })
            .handle(DoSetSignature, (state: KycReduxState, {payload}: Action<DoSetSignaturePayload>) => {
                if (payload.ShouldSetFinalSignature) {
                    const newState: KycReduxState = Utils.merge({}, state);
                    newState.signatureInProgress = true;
                    return newState;
                }
                return state;
            })
            .handle(SetSignatureApproved, (state: KycReduxState, {payload}: Action<SetSignatureApprovedPayload>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.signatureInProgress = false;
                newState.signatureApproved = payload.approved;
                return newState;
            })
            .handle(SetAcknowledgePoliticalExposedDescription, (state: KycReduxState, action: Action<any>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.acknowledgePoliticalExposedDescription = true;
                return newState;
            })
            .handle(SetAcknowledgeTINDescription, (state: KycReduxState, action: Action<any>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.acknowledgeTINDescription = true;
                return newState;
            })
            .handle(SetHasSignature, (state: KycReduxState, action: Action<any>) => {
                const newState: KycReduxState = Utils.merge({}, state);
                newState.hasSignature = true;
                return newState;
            })
            .handle(SetFinancialDetailsType, (state: KycReduxState, {payload}: Action<SetFinancialDetailsTypePayload>) => {
                return Object.assign({}, state, {
                    financialDetailsType: payload.financialDetailsType
                });
            });
    }
}
