import {ActionCreatorDefinition, createAction} from "redux-ts";
import {PageType} from "kbd/enum/PageType";
import {DepositStatus} from "kbd/core/redux/deposit/DepositReduxState";
import {Currency} from "platform/enum/Currency";
import {PackageInfo} from "kbd/protocol/PackageInfo";

export const SetDepositStatusType: string =  "SetDepositStatus";
export const SetDepositActivePageType: string =  "SetDepositActivePage";
export const SetDepositPackagesType: string =  "SetDepositPackages";
export const SetHasFtdType: string =  "SetHasFtd";
export const SetDepositOptionType: string =  "SetDepositOption";
export const SetDepositAmountType: string =  "SetDepositAmount";
export const SetDepositCurrencyType: string =  "SetDepositCurrency";
export const SkipDepositType: string =  "SkipDeposit";
export const DoFinishTopUpType: string =  "DoFinishTopUp";
export const SetTopUpOptionDescriptionHeightType: string =  "SetTopUpOptionDescriptionHeight";
export const ResetTopUpOptionDescriptionHeightType: string =  "ResetTopUpOptionDescriptionHeight";

export interface SetDepositStatusPayload {

    status: DepositStatus;
}

export interface SetDepositActivePagePayload {

    pageType: PageType;
}

export interface SetDepositPackagesPayload {

    packages: PackageInfo[];
}

export interface SetHasFtdPayload {

    hasFtd: boolean;
}

export interface SetDepositOptionPayload {

    index: number;
    amount: number;
}

export interface SetDepositAmountPayload {

    amount: number;
}

export interface SetDepositCurrencyPayload {

    currency: Currency;
}

export interface SetTopUpOptionDescriptionHeightPayload {

    height: number;
}

export const SetDepositStatus: ActionCreatorDefinition<SetDepositStatusPayload, any> = createAction<SetDepositStatusPayload>(SetDepositStatusType);
export const SetDepositActivePage: ActionCreatorDefinition<SetDepositActivePagePayload, any> = createAction<SetDepositActivePagePayload>(SetDepositActivePageType);
export const SetDepositPackages: ActionCreatorDefinition<SetDepositPackagesPayload, any> = createAction<SetDepositPackagesPayload>(SetDepositPackagesType);
export const SetHasFtd: ActionCreatorDefinition<SetHasFtdPayload, any> = createAction<SetHasFtdPayload>(SetHasFtdType);
export const SetDepositOption: ActionCreatorDefinition<SetDepositOptionPayload, any> = createAction<SetDepositOptionPayload>(SetDepositOptionType);
export const SetDepositAmount: ActionCreatorDefinition<SetDepositAmountPayload, any> = createAction<SetDepositAmountPayload>(SetDepositAmountType);
export const SetDepositCurrency: ActionCreatorDefinition<SetDepositCurrencyPayload, any> = createAction<SetDepositCurrencyPayload>(SetDepositCurrencyType);
export const SkipDeposit: ActionCreatorDefinition<any, any> = createAction<any>(SkipDepositType);
export const DoFinishTopUp: ActionCreatorDefinition<any, any> = createAction<any>(DoFinishTopUpType);
export const SetTopUpOptionDescriptionHeight: ActionCreatorDefinition<SetTopUpOptionDescriptionHeightPayload, any> = createAction<SetTopUpOptionDescriptionHeightPayload>(SetTopUpOptionDescriptionHeightType);
export const ResetTopUpOptionDescriptionHeight: ActionCreatorDefinition<any, any> = createAction<any>(ResetTopUpOptionDescriptionHeightType);
