import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {DepositLimitsReduxState} from "kbd/core/redux/limits/DepositLimitsReduxState";
import DepositLimitsEngine from "kbd/core/engine/DepositLimitsEngine";
import {
    SetDepositLimitsFailed,
    SetDepositLimitsFailedPayload,
    SetDepositLimitsQuestionnaire,
    SetDepositLimitsQuestionnairePayload,
    SetDepositLimitsQuestions,
    SetDepositLimitsQuestionsPayload, SetDepositLimitsSubmitting, SetDepositLimitsSubmittingPayload,
    SubmitDepositLimits,
    SubmitDepositLimitsPayload,
    SubmitDepositLimitsType
} from "kbd/core/redux/limits/DepositLimitsReduxActions";
import {LoadLanguageType} from "platform/redux/translation/TranslationActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {QuestionKey} from "kbd/protocol/QuestionKey";
import {AnswerInfo} from "kbd/protocol/AnswerInfo";
import {SelectValue} from "kbd/component/primitive/TTSelect";

export default class DepositLimitsReducer extends Reducer<DepositLimitsReduxState> {

    private static _instance: DepositLimitsReducer;

    public static instance(): DepositLimitsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const depositLimitsEngine: DepositLimitsEngine = Platform.engine(ServiceType.DepositLimits);
        this._middlewareActions.set(LoadLanguageType, depositLimitsEngine.onLoadLanguage);
        this._middlewareActions.set("@@router5/TRANSITION_START", depositLimitsEngine.onChangeRoute);
        this._middlewareActions.set(SubmitDepositLimitsType, depositLimitsEngine.doSubmitDepositLimits);
    }

    public get name(): string {
        return "depositLimits";
    }

    protected setup(builder: ReducerBuilder<DepositLimitsReduxState>): void {
        builder
            .init({
                questionnaire: {},
                answers: new TSMap()
            })
            .handle(SetDepositLimitsQuestionnaire, (state: DepositLimitsReduxState, {payload}: Action<SetDepositLimitsQuestionnairePayload>) => {
                return Object.assign({}, state, {
                    questionnaire: payload.questionnaire || {}
                });
            })
            .handle(SetDepositLimitsQuestions, (state: DepositLimitsReduxState, {payload}: Action<SetDepositLimitsQuestionsPayload>) => {
                if (!Utils.isObjectEmpty(payload.answers)) {
                    const newState: DepositLimitsReduxState = Utils.merge({}, state);
                    const keys: string[] = Object.keys(payload.answers);
                    keys.forEach((key: QuestionKey) => {
                        if (payload.answers[key]) {
                            const options: Array<SelectValue<number>> = payload.answers[key].map((answer: AnswerInfo) => {
                                return {
                                    title: answer.LocalizedAnswer,
                                    value: answer.Id
                                };
                            });
                            newState.answers.set(key, options);
                        }
                    });
                    return newState;
                }
                return state;
            })
            .handle(SubmitDepositLimits, (state: DepositLimitsReduxState, {payload}: Action<SubmitDepositLimitsPayload>) => {
                return Object.assign({}, state, {
                    submitting: true
                });
            })
            .handle(SetDepositLimitsSubmitting, (state: DepositLimitsReduxState, {payload}: Action<SetDepositLimitsSubmittingPayload>) => {
                return Object.assign({}, state, {
                    submitting: payload.submitting
                });
            })
            .handle(SetDepositLimitsFailed, (state: DepositLimitsReduxState, {payload}: Action<SetDepositLimitsFailedPayload>) => {
                return Object.assign({}, state, {
                    submitting: false,
                    failed: payload.failed
                });
            });
    }
}
