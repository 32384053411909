import {Logger} from "platform/logger/Logger";
import {IDpkHandler} from "platform/dpk/Dpk";
import {TSMap} from "typescript-map";
import {DpkType} from "kbd/enum/DpkType";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {SetModal} from "kbd/core/redux/app/AppReduxActions";
import {ModalType} from "kbd/enum/ModalType";
import {SetAcknowledgePoliticalExposedDescription} from "kbd/core/redux/kyc/KycReduxActions";

export class KycDpkHandler implements IDpkHandler<DpkType> {

    protected _logger: Logger = Logger.Of(this.constructor.toString().match(/\w+/g)[1]);

    public dpkTypes(): DpkType[] {
        return [
            DpkType.ShowPoliticalExposedPerson
        ];
    }

    public handle(dpkType: DpkType, parameters: TSMap<string, any>): void {
        if (Utils.isNotNull(dpkType)) {
            switch (dpkType) {
                case DpkType.ShowPoliticalExposedPerson:
                    this._logger.debug("Show political exposed person popup");
                    Platform.dispatch(SetAcknowledgePoliticalExposedDescription({}));
                    Platform.dispatch(SetModal({
                        modalType: ModalType.PoliticalExposedPerson,
                        visible: true
                    }));
                    break;
            }
        }
    }
}
