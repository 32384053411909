export enum ResidenceProofDocumentType {
    //
    // Summary:
    //     Utility bill.
    UtilityBill = 1,
    //
    // Summary:
    //     Bank statement.
    BankStatement = 2,
    //
    // Summary:
    //     Credit card statement.
    CreditCardStatement = 3,
    //
    // Summary:
    //     Local authority tax bill.
    LocalAuthorityTaxBill = 4,
    //
    // Summary:
    //     Other.
    Other = 5
}
