import {TSMap} from "typescript-map";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import Utils from "platform/util/Utils";
import {DocumentStatus} from "kbd/protocol/DocumentStatus";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {DocumentCreditCard} from "kbd/protocol/response/GetUsedCreditCardsResponse";
import {isDocumentApproved, isDocumentApprovedInReview, isDocumentRejected} from "kbd/core/util/KycUtil";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {ProofSubDocumentTypeInfo} from "kbd/protocol/ProofSubDocumentTypeInfo";
import Platform from "platform/Platform";
import {StoreState} from "kbd/core/redux/StoreState";

export const MAX_ADDITIONAL_DOCUMENTS: number = 3;

enum DocumentGroup {

    Approved,
    ApprovedInReview,
    Rejected,
}

const compareStatus = (s1: DocumentStatus, s2: DocumentStatus, status: DocumentStatus): number => {
    if (s1 === status && s2 !== status) {
        return -1;
    } else if (s1 !== status && s2 === status) {
        return 1;
    } else {
        return 0;
    }
};

const compareDocuments = (docs1: DocumentInfo[], docs2: DocumentInfo[]): number => {
    const d1: DocumentInfo = docs1[0];
    const d2: DocumentInfo = docs2[0];
    let result: number = Utils.compareUndefined(d1, d2) * -1;
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.Rejected);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.AutomaticallyRejected);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.PendingReview);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.PendingAutomaticVerification);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.ApprovedByComplianceOfficer);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.Approved);
    }
    if (result === 0) {
        result = compareStatus(d1.Status, d2.Status, DocumentStatus.AutomaticallyApproved);
    }
    return result;
};

export const DocTypeLocalizedName = (docType: ProofDocumentType): string => {
    if (docType) {
        const {documentTypes} = Platform.reduxState<StoreState>().documents;
        const docTypeInfo: ProofDocumentTypeInfo = documentTypes.get(docType);
        if (docTypeInfo) {
            return docTypeInfo.LocalizedName;
        }
    }
    return  null;
}

export const docSubTypeLocalizedName = (docType: ProofDocumentType, subDocType: number | string): string => {
    if (docType) {
        const {documentTypes} = Platform.reduxState<StoreState>().documents;
        const docTypeInfo: ProofDocumentTypeInfo = documentTypes.get(docType);
        if (docTypeInfo) {
            for (let i = 0; i < docTypeInfo.SubDocumentTypes.length; i++) {
                const subDocumentTypeInfo: ProofSubDocumentTypeInfo = docTypeInfo.SubDocumentTypes[i];
                if (subDocumentTypeInfo.Id === subDocType) {
                    return DocSubTypeLocalizedName(subDocumentTypeInfo);
                }
            }
        }
    }
    return  null;
}

export const DocSubTypeLocalizedName = (subTypeInfo: ProofSubDocumentTypeInfo): string => {
    return subTypeInfo?.LocalizedName ? subTypeInfo.LocalizedName : Translations.text(TranslationKey.document);
}

export const documentsWizard = (
    documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>,
    documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>>,
    creditCards: DocumentCreditCard[]
): ProofDocumentType[] => {
    const typesToSort: ProofDocumentTypeInfo[] = [];
    if (Utils.isMapNotEmpty(documentTypes)) {
        documentTypes.forEach((info: ProofDocumentTypeInfo, docType: ProofDocumentType) => {
            if (docType === ProofDocumentType.ProofOfCC) {
                const documents: TSMap<number | string, DocumentInfo> = documentStatuses.get(ProofDocumentType.ProofOfCC);
                const NotRejectedCreditCardDocs: Set<string> = new Set<string>();
                if (documents) {
                    documents.forEach((doc: DocumentInfo) => {
                        if (Utils.isNotNull(doc) && !isDocumentRejected(doc)) {
                            NotRejectedCreditCardDocs.add(doc.LastFourDigitsOfCreditCard);
                        }
                    });
                }
                let CanUploadCreditCardDocs: boolean = false;
                if (Utils.isArrayNotEmpty(creditCards)) {
                    for (let i = 0; i < creditCards.length; i++) {
                        const CreditCard: DocumentCreditCard = creditCards[i];
                        if (CreditCard?.Last4digits && !NotRejectedCreditCardDocs.has(CreditCard.Last4digits)) {
                            CanUploadCreditCardDocs = true;
                            break;
                        }
                    }
                }
                if (CanUploadCreditCardDocs) {
                    typesToSort.push(info);
                }
            } else if (docType === ProofDocumentType.Miscellaneous) {
                typesToSort.push(info);
            } else {
                const documents: TSMap<number | string, DocumentInfo> = documentStatuses.get(docType);
                if (Utils.isMapNotEmpty(documents)) {
                    let hasNotRejected: boolean = false;
                    const docs: DocumentInfo[] = documents.values();
                    for (let i = 0; i < docs.length; i++) {
                        const doc: DocumentInfo = docs[i];
                        if (doc && !isDocumentRejected(doc)) {
                            hasNotRejected = true;
                        }
                    }
                    if (!hasNotRejected) {
                        typesToSort.push(info);
                    }
                } else {
                    typesToSort.push(info);
                }
            }
        });
    }
    return typesToSort.sort((i1: ProofDocumentTypeInfo, i2: ProofDocumentTypeInfo) => Utils.compareBoolean(i1.IsRequired, i2.IsRequired)).map((i) => i.Id);
};

const documentGroup = (Document: DocumentInfo): DocumentGroup => {
    if (Document) {
        if (isDocumentApproved(Document)) {
            return DocumentGroup.Approved;
        } else if (isDocumentApprovedInReview(Document)) {
            return DocumentGroup.ApprovedInReview;
        } else if (isDocumentRejected(Document)) {
            return DocumentGroup.Rejected;
        }
    }
    return null;
};

const groupDocuments = (Documents: DocumentInfo[], callback: (Documents: DocumentInfo[]) => void): void => {
    if (Utils.isArrayNotEmpty(Documents)) {
        const groups: TSMap<DocumentGroup, DocumentInfo[]> = new TSMap<DocumentGroup, DocumentInfo[]>();
        Documents.forEach((Document: DocumentInfo) => {
            if (Document && !Document.ShouldHideFromFullDocumentUpload) {
                const docGroup: DocumentGroup = documentGroup(Document);
                if (Utils.isNotNull(docGroup)) {
                    const docsInGroup: DocumentInfo[] = groups.get(docGroup) || [];
                    if (Utils.isArrayEmpty(docsInGroup)) {
                        groups.set(docGroup, docsInGroup);
                        callback(docsInGroup);
                    }
                    docsInGroup.push(Document);
                } else {
                    callback([Document]);
                }
            }
        });
    }
}

export const documentsOverviewList = (
    documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>>
): DocumentInfo[][] => {
    const Documents: DocumentInfo[][] = [];
    if (documentStatuses) {
        documentStatuses.forEach((statuses: TSMap<number | string, DocumentInfo>, docType: ProofDocumentType) => {
            if (Utils.isMapNotEmpty(statuses)) {
                if (docType === ProofDocumentType.ProofOfCC || docType === ProofDocumentType.Miscellaneous) {
                    groupDocuments(statuses.values(), (docs: DocumentInfo[]): void => {
                        Documents.push(docs);
                    });
                } else {
                    statuses.forEach((Document: DocumentInfo) => {
                        if (Document && !Document.ShouldHideFromFullDocumentUpload) {
                            Documents.push([Document]);
                        }
                    });
                }
            }
        });
    }
    Documents.sort(compareDocuments);
    return Documents;
}

export const documentsSortCreditCards = (documents: DocumentInfo[], creditCards: DocumentCreditCard[]): DocumentCreditCard[] => {
    if (documents && creditCards) {
        const cards: DocumentCreditCard[] = [...creditCards];
        const rejected: DocumentInfo[] = [];
        const approved: DocumentInfo[] = [];
        const other: DocumentInfo[] = [];
        documents.forEach((Document: DocumentInfo) => {
            if (Document && Document.LastFourDigitsOfCreditCard) {
                if (isDocumentRejected(Document)) {
                    rejected.push(Document);
                } else if (isDocumentApproved(Document)) {
                    approved.push(Document);
                } else {
                    other.push(Document);
                }
            }
        });
        cards.sort((c1: DocumentCreditCard, c2: DocumentCreditCard) => {
            let result: number = 0;
            const c1Rejected: boolean = Utils.isNotNull(rejected.filter((Document: DocumentInfo) => c1.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
            const c2Rejected: boolean = Utils.isNotNull(rejected.filter((Document: DocumentInfo) => c2.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
            result = Utils.compareBoolean(c1Rejected, c2Rejected);
            if (result === 0) {
                const c1HasNtDoc: boolean = Utils.isNull(other.filter((Document: DocumentInfo) => c1.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
                const c2HasNtDoc: boolean = Utils.isNull(other.filter((Document: DocumentInfo) => c2.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
                result = Utils.compareBoolean(c1HasNtDoc, c2HasNtDoc);
                if (result === 0) {
                    const c1Approved: boolean = Utils.isNotNull(approved.filter((Document: DocumentInfo) => c1.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
                    const c2Approved: boolean = Utils.isNotNull(approved.filter((Document: DocumentInfo) => c2.Last4digits === Document.LastFourDigitsOfCreditCard)[0]);
                    result = Utils.compareBoolean(!c1Approved, !c2Approved);
                }
            }
            return result;
        });
        return cards;
    }
    return creditCards || [];
}
