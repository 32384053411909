import {ActionCreatorDefinition, createAction} from "redux-ts";
import {DepositLimitForm} from "kbd/protocol/request/SubmitDepositLimitRequest";
import {AnswerInfo} from "kbd/protocol/AnswerInfo";

export const SetDepositLimitsQuestionnaireType: string =  "SetDepositLimitsQuestionnaire";
export const SetDepositLimitsQuestionsType: string =  "SetDepositLimitsQuestions";
export const SubmitDepositLimitsType: string =  "SubmitDepositLimits";
export const SetDepositLimitsSubmittingType: string =  "SetDepositLimitsSubmitting";
export const SetDepositLimitsFailedType: string =  "SetDepositLimitsFailed";

export interface SetDepositLimitsQuestionnairePayload {

    questionnaire: DepositLimitForm;
}

export interface SetDepositLimitsQuestionsPayload {

    answers: {[key: string]: AnswerInfo[]};
}

export interface SubmitDepositLimitsPayload {

    values: DepositLimitForm;
}

export interface SetDepositLimitsSubmittingPayload {

    submitting: boolean;
}

export interface SetDepositLimitsFailedPayload {

    failed: boolean;
}

export const SetDepositLimitsQuestionnaire: ActionCreatorDefinition<SetDepositLimitsQuestionnairePayload, any> = createAction<SetDepositLimitsQuestionnairePayload>(SetDepositLimitsQuestionnaireType);
export const SetDepositLimitsQuestions: ActionCreatorDefinition<SetDepositLimitsQuestionsPayload, any> = createAction<SetDepositLimitsQuestionsPayload>(SetDepositLimitsQuestionsType);
export const SubmitDepositLimits: ActionCreatorDefinition<SubmitDepositLimitsPayload, any> = createAction<SubmitDepositLimitsPayload>(SubmitDepositLimitsType);
export const SetDepositLimitsSubmitting: ActionCreatorDefinition<SetDepositLimitsSubmittingPayload, any> = createAction<SetDepositLimitsSubmittingPayload>(SetDepositLimitsSubmittingType);
export const SetDepositLimitsFailed: ActionCreatorDefinition<SetDepositLimitsFailedPayload, any> = createAction<SetDepositLimitsFailedPayload>(SetDepositLimitsFailedType);
