import "react-app-polyfill/ie11";

import {Boot} from "kbd/entry/Boot";
import AsyncComponent from "platform/component/async/AsyncComponent";
import {PageType} from "kbd/enum/PageType";
import {ProductType} from "kbd/entry/ProductType";
import {KycService} from "kbd/core/service/KycService";
import {DocumentsService} from "kbd/core/service/DocumentsService";
import {DepositService} from "kbd/core/service/DepositService";
import {DepositLimitsService} from "kbd/core/service/DepositLimitsService";
import {injectGeoScripts, injectGoogleMapsScripts} from "kbd/core/util/KycUtil";

const KycAsync = AsyncComponent(() => {
    return import("../component/root/KycRoot");
});

const KycMobileAsync = AsyncComponent(() => {
    return import("../component/root/KycRootMob");
});

Boot({
    Desktop: KycAsync,
    Mobile: KycMobileAsync,
    product: ProductType.Kyc,
    defaultPage: PageType.KycWelcome,
    startPath: PageType.KycWelcome,
    services: [
        {service: KycService.instance()},
        {service: DocumentsService.instance()},
        {service: DepositService.instance()},
        {service: DepositLimitsService.instance()}
    ]
}).then(() => {
    injectGeoScripts();
    injectGoogleMapsScripts();
}).catch((e) => {
    throw e;
});
