import {TSMap} from "typescript-map";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import {ErrorInfo} from "kbd/protocol/ErrorInfo";
import {FileType} from "kbd/enum/FileType";
import {DocumentCreditCard} from "kbd/protocol/response/GetUsedCreditCardsResponse";

export interface DocumentsReduxState {

    documentTypes: TSMap<ProofDocumentType, ProofDocumentTypeInfo>;
    documentStatuses: TSMap<ProofDocumentType, TSMap<number | string, DocumentInfo>>;
    documentContents: TSMap<ProofDocumentType, TSMap<number | string, DocumentPayload[]>>;
    documentSubTypes: TSMap<ProofDocumentType, number>;
    uploadCancelInProgress?: boolean;
    errors: TSMap<ProofDocumentType, TSMap<number | string, ErrorInfo[]>>;
    kycCreditCards: DocumentCreditCard[];
    creditCards: DocumentCreditCard[];
    creditCardsUploaded: TSMap<string, boolean>;
    webCamBlocked?: boolean;
    webCamDocType?: ProofDocumentType;
    webCamSubDocType?: number | string;
    webCamDocIndex?: number;
    webCamAdditional?: boolean;
    wizardDocsNavigation: ProofDocumentType[];
    wizardDocsIndex: number;
    selectedDocumentType?: ProofDocumentType;
    selectedCreditCard?: DocumentCreditCard;
}

export enum DocumentPayloadType {

    File,
    Photo,
}

export interface DocumentPayload {

    payloadType: DocumentPayloadType;
    payload: File | string;
    fileType: FileType;
    fileName: string;
    additional: boolean;
}
