import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "kbd/enum/ServiceType";
import DocumentsEngine from "kbd/core/engine/DocumentsEngine";
import DocumentsReducer from "kbd/core/redux/documents/DocumentsReducer";
import {DocumentsState} from "kbd/core/state/DocumentsState";

export class DocumentsService implements Service<DocumentsEngine, DocumentsState> {

    private static _instance: DocumentsService;

    private constructor() {
    }

    public static instance(): DocumentsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Documents;
    }

    public reducers(): Array<Reducer<any>> {
        return [DocumentsReducer.instance()];
    }

    public engine(): DocumentsEngine {
        return DocumentsEngine.instance();
    }

    public state(): DocumentsState {
        return DocumentsState.instance();
    }
}
